// authService.js
const authService = {
    login: async (credentials) => {
      const response = await fetch('https://auth.addosser.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      let result = await response.json();
      if (!response.ok) {
        
        throw new Error(result.message);
      }
  
      const user = result;
      console.log(user)
      return user;
    },
    user: async (credentials) => {
        const response = await fetch('https://auth.addosser.com/users/profile', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${credentials}`
          }
        });
        
        let result = await response.json();
       
        if (!response.ok) {
          
          throw new Error(result.message);
        }
        
        const user = result;
        console.log(user, "user")
        return user;
      },
    team: async (credentials, user) => {

      const response = await fetch(`https://auth.addosser.com/teams/teamByTeamLead/${user?._id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${credentials}`
        }
      });
      
      let result = await response.json();
     
      if (!response.ok) {
        
        throw new Error(result.message);
      }
      console.log(result, "team")
      return result?.map((item)=>item?.orbitID)?.filter((it)=>it != undefined)
    },
    territory: async (credentials, territory) => {
      console.log(territory)
      
        const response = await fetch('https://auth.addosser.com/territories/savingsWithIDs', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${credentials}`
          }
        });
        
        let result = await response.json();
       
        if (!response.ok) {
          
          throw new Error(result.message);
        }
        console.log(result, "territory")
        return result?.filter((item) => item?.id == territory)[0]?.orbitID.map(String).map(num => `${num}`).join(',')
      },
    logout: async () => {
      // Perform logout logic here, e.g., clear local storage or make an API call
      // ...
  
      // Return any necessary data
    },
  };
  
  export default authService;
  