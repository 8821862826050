import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts'
// const Chart = []
const DynamicChart = ({ id, categories, data, chartType, xType, ySymbol, yText }) => {
  
  const [columnBarOptions, setColumnBarOptions] = useState({
    options: {
      chart: {
        type: chartType,
        height: 350,
        width:'100%'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        type: xType || 'text',
        categories: categories || [] // Use the provided categories or an empty array as the default  
      },
      yaxis: {
        title: {
          text: ySymbol +"("+yText+")"
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right'
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return `${ySymbol} ${val} ${yText}`
      //     }
      //   }
      // }
    },
  })
  
  const [areaLineOptions, setAreaLineChartOptions] = useState({
    chart: {
      id: id,
      type: chartType,
      width:'100%'
    },
    xaxis: {
      type: xType || 'text',
      categories: categories || [] // Use the provided categories or an empty array as the default
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }},
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    },
  });

  const [pieDonutOptions, setPieDonutOptions] = useState({
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: categories,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  })

  return (
    <div style={{ width: '100%', height: '100%' }}>
    <div style={{ width: '100%', height: '100%' }}>
      <Chart options={chartType === "pie" || chartType === "donut" ? pieDonutOptions : chartType === "column" || chartType === "bar" ? columnBarOptions : areaLineOptions} series={data} type={chartType} />
    </div>
  </div>
  );
};

export default DynamicChart;
