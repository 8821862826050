// useFormSubmission.js
import { useState } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import useApiUrls from './useApiUrls';
const useFormSubmission = (apiName) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { getUrlByName } = useApiUrls();
  const token = useSelector((state) => state.auth.token); // Retrieve the token from Redux state


  const defaultApiName = 'Auth API';
  let baseURL = getUrlByName(apiName || defaultApiName);
 
  const submitForm = async (url, formData, contentType) => {
    setIsSubmitting(true);
    setSubmitError(null);
    let headers =   await contentType !== "multipart" ?  {
      'Authorization': `Bearer ${token}`, 
      'Content-Type':  'application/json'
    } : {
      'Authorization': `Bearer ${token}`
    }
    // console.log(`${baseURL}/${url}`)
    try {
      const response = await fetch(`${baseURL}/${url}`, {
        method: 'POST',
        headers: headers,
        body: contentType == "multipart" ? formData : JSON.stringify(formData)
        // Add any necessary headers and body data
      });
      // console.log('Response:', response);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        // console.log('Result:', result);

        if (result && result.status === 200) {
        // Form submitted successfully
        return { submitSuccess: true, responseBody: result };

      } else {

        throw new Error(result.error || result.message || 'Form submission failed');
      
      }
      
    } catch (error) {
      // console.log(error)
      setSubmitError(`Error: ${error.message}`);
      return { submitSuccess: false, responseBody: false };

    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, submitError, submitForm };
};

export default useFormSubmission;
