import { Box, Spinner, Grid, Text, Select, SimpleGrid, Table, useMediaQuery,
  Tbody, Td, Th, Thead, Tr, Button} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import DynamicChart from '../../common/components/DynamicChart';
import DashboardLayout from '../../common/components/DashboardLayout';
import { useSelector } from 'react-redux';
import SelectByPermission from '../../common/components/SelectByPermission';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import ReportHeader from '../components/ReportHeader';

const TrendAnalysisPage = () => {
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const handleProductsSelected = (selectedIds) => {
    setSelectedProductIds(selectedIds);
  };
  
  const [analysisType, setAnalysisType] = useState('month');
  const userPermission = useSelector((state) => state?.auth?.user?.role);
  let [loading, setLoading] = useState(false);
  let [ data , setData ] =useState();  
   const { user } = useSelector((state) => state.auth);
  let [ ids , setIds ] =useState(user?._id);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); // Set to 'today' initially
  const [accountOpened, setAccounts] = useState()
  const [ permission, setPermission] = useState(null);
  
  
  useEffect(() => {
  
    const fetchDat = async () => {    
    try{
   
      await dataFetch(userPermission?._id, analysisType, ids, permission)
    
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
     setLoading(false);
    };
    }  
    fetchDat();
    }, [analysisType]); 


  const handleSelectChange = async(event, selectId) => {
    setIds(event);
    setPermission(selectId)
  
  }; 
  
  const searchButton = async() => {
    await dataFetch(userPermission?._id, analysisType, ids, permission);
  }

  
  
  const { submitError, submitForm } = useFormSubmission("Savings API");
  
  const dataFetch = async(role, analysisType, id, permission)=>{
    setLoading(true);
    let { submitSuccess, responseBody, submitError } = await submitForm('fetchTrend',{ 
      role, analysisType, id, permission})
   
       if (submitSuccess) {
        console.log(responseBody.data)
        setData(responseBody.data);
               
      
      }
    
    setLoading(false);
    

   
  }

  return (
    <DashboardLayout>

    <Box p={4}>
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} className='no-print'>
   
      <Text mb={4} fontSize="11" fontWeight={600}>
      <SelectByPermission userPermission={userPermission} 
      user={user} handleSelectChangeCustom={handleSelectChange}/> 
      </Text>

        <Select value={analysisType} 
        onChange={e => setAnalysisType(e.target.value)}
        fontSize={12}
        bg="white"
        >
          <option></option>
          <option value="day">Day by Day</option>
          <option value="month">Month by Month</option>
          <option value="week">Week by Week</option>
          <option value="year">Year by Year</option>
        </Select>
      </Grid>
      <Box justifyContent={'right'} display={'flex'} mb={3} className='no-print'>
    <Button onClick={searchButton} bg={"darkblue"} color={'white'} fontSize={12}> Submit</Button>
  </Box>
      { loading ? <Spinner size="lg" /> :
      <Box py={2} background={'white'} borderRadius={10}>
             <ReportHeader></ReportHeader>
    
          <Text fontSize={'18px'} fontWeight={600} justifyContent="center" display={'flex'} alignSelf={'center'}>{analysisType.toUpperCase()} BY {analysisType.toUpperCase()} TREND ON SAVINGS AND WITHDRAWAL  </Text>
    {/* Summary Section */}

      {/* Display the trend analysis chart */}
      <SimpleGrid columns={1} spacing={4}>
        <Box p={4} borderWidth="1px" borderRadius="md">
          <DynamicChart
            id="trend-chart"
            categories={data?.depositHistory?.map(item => 
              analysisType == "day" ? `${item?.DAY}/${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "month" ? `${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "week" ? `${item?.WEEK}/${item?.YEAR}` : 
              `${item?.YEAR}` 
             )}
            data={[{
              name: "Deposit",
              data: data?.depositMonitor?.map(item => item?.TOTALAMOUNT)
            },{
              name: "Withdrawal",
              data: data?.depositHistory?.map(item => item?.TOTALAMOUNT)
            }]}
            chartType={'area'}
            xType="category"
            ySymbol="N"
            yText="Amount"
          />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={[1,2]} spacing={4}>
      <Table variant="simple" mt={8} fontSize={12}>
        <Thead>
          <Tr>
            <Th>Withdrawal Period</Th>
            <Th>Total Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.depositHistory?.map((item) => (
            <Tr key={item.id}>
              <Td>{analysisType == "day" ? `${item?.DAY}/${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "month" ? `${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "week" ? `${item?.WEEK}/${item?.WEEK}` : 
              `${item?.year}`}</Td>
              <Td>{Number(item.TOTALAMOUNT).toLocaleString()}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Table variant="simple" mt={8} fontSize={12}>
        <Thead>
          <Tr>
            <Th>Deposit Period</Th>
            <Th>Total Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.depositMonitor?.map((item) => (
            <Tr key={item.id}>
              <Td>{analysisType == "day" ? `${item?.DAY}/${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "month" ? `${item?.MONTH}/${item?.YEAR}` : 
              analysisType == "week" ? `${item?.WEEK}/${item?.YEAR}` : 
              `${item?.YEAR}`}</Td>
              <Td>{Number(item.TOTALAMOUNT).toLocaleString()}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </SimpleGrid>
      </Box>}
    </Box>
    </DashboardLayout>
  );
};

export default TrendAnalysisPage;
