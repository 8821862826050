import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';

const TransactionTable = ({ dataObject }) => {
  // Check if dataObject is empty or undefined
  if (!dataObject || Object.keys(dataObject).length === 0) {
    return <Text>No data available</Text>;
  }

  const firstAccount = dataObject.allDates;
  const allDates = ["Account Number", ...firstAccount];
  
  return (
  <Box bg={'white'}>
      <Table variant="striped"  mb={6}>
        <Thead>
          <Tr>
            {allDates.map((headerText, headerIndex) => {
              if(headerIndex == 0){
                return(<Th key={headerIndex} colSpan={3}>{headerText}</Th>)
              }else{
                return(<Th key={headerIndex}>{headerText}</Th>)
              }
            })}
          </Tr>
        </Thead>
        <Tbody fontSize={12}>
          {Object.keys(dataObject).map((accountNumber, index) => {
            const data = dataObject[accountNumber];
        
            return(
            <React.Fragment key={accountNumber}>
                <Tr>
                
                {(!isNaN(Number(accountNumber))) ? (
                  <Td colSpan={3}>
                    Account Name: {dataObject[accountNumber]?.transactions?.[0]?.ACCT_NM}<br/>
                    Account Number: {accountNumber}
                  </Td>
                ) : (
                  <Td>{accountNumber == "collectionCount" ? "Collection Count" : accountNumber == "visitationCount" ? "Visitation Count" : accountNumber == "collectedAmount" ? "Collected": null}</Td>
                )}
                {
              (!isNaN(Number(accountNumber))) ? (
                allDates.slice(1).map((TRAN_DT, ind) =>{ 
                  console.log(data?.transactionsByDate?.[TRAN_DT]?.[0]?.TXN_AMT)
                  return(
                  <Td key={TRAN_DT}>
                    <span>&#8358;</span>{Number(data?.transactionsByDate?.[TRAN_DT]?.[0]?.TXN_AMT || 0).toLocaleString()}
                  </Td>
                )})
              ) : (
                (accountNumber === "collectionCount") ?  
                <Td colSpan={allDates.length }>{dataObject.collectionCount}</Td> 
                : (accountNumber === "visitationCount") ? (
                  <Td colSpan={allDates.length }>{dataObject.visitationCount}</Td>
                ) : (
                  (accountNumber === "collectedAmount") ? (
                    <Td colSpan={allDates.length }>
                      <span>&#8358;</span>{Number(dataObject.collectedAmount).toLocaleString()}
                    </Td>
                  ) : null
                )
              )
            }

                </Tr>
                       
              </React.Fragment>)
        
          })}
        </Tbody>
      </Table>
      </Box>
  );
};

export default TransactionTable;
