import { Table, Tbody, Td, Th, Thead, Tr, Box , Grid, Text, useMediaQuery, Spinner, Button} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import DashboardLayout from '../../common/components/DashboardLayout';
import DateFilter from '../../common/components/DateFilter';
import { useSelector } from 'react-redux';
import useFetch from '../../common/hooks/useFetch';
import useMonthsUsed from '../../common/hooks/usedMonths';
import SelectByPermission from '../../common/components/SelectByPermission';
import ReportHeader from '../components/ReportHeader';

const MassMarketing = () => {
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const handleProductsSelected = (selectedIds) => {
    setSelectedProductIds(selectedIds);
  }; 
  const userPermission = useSelector((state) => state?.auth?.user?.role);
  let [loading, setLoading] = useState(false);
  let [ data , setData ] =useState();
  let [ chartData , setChartData ] = useState([]);
  const { user } = useSelector((state) => state.auth);
  let [ ids , setIds ] =useState(user?._id);
  let [withdrawal, setWithdrawal] = useState();
  let [cabal , setCabal] = useState();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); // Set to 'today' initially
  const [accountOpened, setAccounts] = useState()
  const [ daysSelected, numOfDays] = useState(1);
  const [ permission, setPermission] = useState(null);
  let [customStartDate, setCustomStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [customEndDate, setCustomEndDate] = useState(new Date().toISOString().split('T')[0]);
  const { data: productData, loading: productLoading, error: productError, fetchData: fetchProduct } = useFetch("Savings API");
  const { data: caseloadData, loading: caseloadLoading, error: caseloadError, fetchData: fetchCaseload } = useFetch("Savings API");
  const { data: visitationData, loading: visitationLoading, error: visitationError, fetchData: fetchVisitation } = useFetch("Savings API");
  const { data: dmotargetData, loading: dmotargetLoading, error: dmotargetError, fetchData: fetchDMOTarget } = useFetch("Savings API");
  const { data: activesaversData, loading: activesaversLoading, error: activesaversError, fetchData: fetchActiveSavers } = useFetch("Savings API");
  const { data: dailymobilizationData, loading: dailymobilizationLoading, error: dailymobilizationError, fetchData: fetchDailyMobilization } = useFetch("Savings API");
  const monthsUsed = useMonthsUsed(user?.startDate);  


  useEffect(() => {
  
    const fetchDat = async () => {    
      try {
        fetchProduct("productCategory");
        fetchCaseload("caseload");
        fetchVisitation("visitation");
        fetchDMOTarget("dmotarget");
        fetchActiveSavers("activesavers");
        fetchDailyMobilization("dailymobilization"); 
    
        // await dataFetch(userPermission?._id, customStartDate, customEndDate, ids, permission)
    

  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
  };
    fetchDat();
  }, []); 

  const handleFilterChange = async (value, startDate, endDate) => {
    setFilterValue(value);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);   
    
  };

  const handleSelectChange = async(event, selectId) => {
    
    setIds(event);
    setPermission(selectId)
    // await dataFetch(userPermission?._id, customStartDate, customEndDate, event, selectId);
  };
  
  const searchButton = async() => {
    await dataFetch(userPermission?._id, customStartDate, customEndDate, ids, permission)
    setPermission(null)
  }

  const dataFetch = async(role, startDate, endDate, id, permission)=>{
    setLoading(true);
    let { submitSuccess, responseBody, submitError } = await submitForm('fetchDetails',{ 
      role, startDate, endDate, id, permission})
    let { submitSuccess: cabalSuccess, responseBody: cabalBody,
      submitError: cabalError } = await submitForm("fetchCabal", { 
        role, startDate, endDate, id, permission});
      let { submitSuccess: accountSuccess, responseBody: accountBody,
      submitError: accountError } = await submitForm("accountOpened", { 
        role, startDate, endDate, id, permission})
      let { submitSuccess: withdrawSuccess, responseBody: withdrawBody,
         submitError: withdrawalError } = await submitForm("fetchWithdrawal", {
          startDate,
          endDate,
          ACCTS: await cabalBody?.data?.cabalMix?.accounts.map((item)=> item.ACCT_NO),
         })
   
       if (submitSuccess) {
        setData(responseBody.data);
        console.log(data, 'data')
        setChartData([
          { 
          name: "Amount Collected in thousands", 
          data: responseBody?.data?.totalAmountData?.sort(compareDates).map((item) => item.totalAmount)
        },
        { 
          name: "Collection", 
          data: responseBody?.data?.collectionData?.sort(compareDates).map((item) => item.collectionCount)
        },
        {
          name: "Visitation",
          data: responseBody?.data?.visitationData?.sort(compareDates)
        }
      ])
      
      }
       if (cabalSuccess) {
      setCabal(cabalBody.data);
      console.log(cabal, 'data')
            
    if (accountSuccess) {
      setAccounts(accountBody.data);
      
      console.log(accountOpened, "accounts Opened")
    
    if(withdrawSuccess){
      setWithdrawal(withdrawBody.data)
    }
    }
    } 
    setLoading(false);
    

   
  }
    const compareDates = (a, b) => {
    const formattedDateA = new Date(a.dateKey);
    const formattedDateB = new Date(b.dateKey);
    // console.log(formattedDateA, formattedDateB)
    return formattedDateA - formattedDateB
  };
  
  
  const { submitError, submitForm } = useFormSubmission("Savings API");
  useEffect(() => {
    // const fetchData = async () => {
    //   setLoading(true);
    //   let { submitSuccess, responseBody, submitError } = await submitForm("fetchDashboard", payload);
     
    // if (submitSuccess) {
    //     setData(responseBody.data);
    //     // console.log(responseBody.data)
    //     setChartData([
    //       { 
    //       name: "Amount Collected in thousands", 
    //       data: responseBody?.data?.totalAmountData?.sort(compareDates).map((item) => item.totalAmount)
    //     },
    //     { 
    //       name: "Collection", 
    //       data: responseBody?.data?.collectionData?.sort(compareDates).map((item) => item.collectionCount)
    //     },
    //     {
    //       name: "Visitation",
    //       data: responseBody?.data?.visitationData?.sort(compareDates)
    //     }
    //   ])
    //   }
     
    // if (cabalSuccess) {
    //   setCabal(cabalBody.data);
    // }
            
    // if (accountSuccess) {
    //   setAccounts(accountBody.data);

    // }
      
    // if(withdrawSuccess){
    //   setWithdrawal(withdrawBody.data)
    // }else{
    //   setLoading(false);
    // }

    // setLoading(false);
  
    // };

    // if (ids != undefined)
    //   fetchData();
  }, [filterValue, customStartDate, customEndDate, ids]);

     
  return (
    <DashboardLayout>

    <Box p={4}>
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} 
    className='no-print'
    >
   
   <Text mb={4} fontSize="11" fontWeight={600}>
   <SelectByPermission userPermission={userPermission} 
   user={user} handleSelectChangeCustom={handleSelectChange} page="mass"/> 
   </Text>
 <DateFilter
       onFilterChange={handleFilterChange}
       useCustomDateFormat={true} 
       numOfDays={numOfDays}
     />
 </Grid>
 <Box justifyContent={'right'} display={'flex'} mb={3} className='no-print'>
    <Button onClick={searchButton} bg={"darkblue"} color={'white'} fontSize={12}> Submit</Button>
  </Box>
    { loading ? <Spinner size="lg" /> :
    <Box bg="white" borderRadius={10} p={3}>
          <ReportHeader></ReportHeader>
    


      <Text fontSize={'18px'} fontWeight={600} justifyContent="center" display={'flex'} alignSelf={'center'}>MASS MARKETING REPORT FROM {customStartDate} TO {customEndDate} </Text>
      {/* Summary Section */}
      <section>
        <Table>
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* <Tr>
              <Td>Total Collections</Td>
              <Td>{data?.collectionCount}</Td>
            </Tr>
            <Tr>
              <Td>Total Visitations</Td>
              <Td>{data?.visitationCount}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Collected</Td>
              <Td><span>&#8358;</span>{data?.collectedAmount?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Accounts in Cabal</Td>
              <Td>{cabal?.totalAccounts}</Td>
            </Tr>
            <Tr>
              <Td>Total Active Accounts in Cabal</Td>
              <Td>{cabal?.totalActiveAccounts}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn (Non-AFTA)</Td>
              <Td><span>&#8358;</span>{withdrawal?.nonAfta?.reduce((total, transaction) => total + transaction.TXN_AMT, 0)?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn (AFTA)</Td>
              <Td><span>&#8358;</span>{withdrawal?.afta?.reduce((total, transaction) => total + transaction.TXN_AMT, 0)?.toLocaleString() || 0}</Td>
            </Tr> */}
            <Tr>
              <Td>Account Opened</Td>
              <Td>{accountOpened?.length || 0}</Td>
            </Tr>
            {/* <Tr>
              <Td>Current Balance of Active Accounts</Td>
              <Td> <span>&#8358;</span>{cabal?.cabalMix?.balance?.toLocaleString() || 0 } </Td>
            </Tr> */}
          </Tbody>
        </Table>
      </section>

      {/* Category Mix Section */}
      <section>

        {/* Active Savers */}
        
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Accounts</Th>
              <Th>%</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={10}>

            {cabal?.cabalMix?.categoryCounts && Object.keys(cabal?.cabalMix?.categoryCounts).map((category, index) => (
              <Tr 
              fontSize={10}
              key={category}>
              <Td fontWeight={600}>{category === "null" ? "Non AFTA" : category}</Td>
              <Td>{loading ? <Spinner size="sm" /> : cabal?.cabalMix?.categoryCounts[category]?.count || 0}</Td>
              <Td>{loading ? <Spinner size="sm" /> : cabal?.cabalMix?.categoryCounts[category]?.percentage || 0} </Td>
              {/* <Td>{loading ? <Spinner size="sm" /> : activeMix(category) || 0}</Td> */}
              
            </Tr>   
            ))}
            </Tbody>
        </Table>
        
      </section>
      </Box>}
      
      </Box>
      
    </DashboardLayout>
  );
};

export default MassMarketing;
