// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Grid, Box, useMediaQuery, Flex, Heading, Text, VStack, Stat, StatLabel, StatNumber, Spinner } from '@chakra-ui/react';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import DashboardLayout from '../../common/components/DashboardLayout';
import TransactionTable from '../components/TransactionTable';
import DateFilter from '../../common/components/DateFilter';
import CustomSelect from '../../common/components/CustomSelect';
import { useSelector } from 'react-redux';
const CollectionUsers = () => {

  const userPermission = useSelector((state) => state?.auth?.user?.role);
  let [loading, setLoading] = useState(false);
   let [ data , setData ] =useState();
  let [ userType , setUserType ] =useState(undefined);
  let [ ids , setIds ] =useState(undefined);
  let [ chartData , setChartData ] = useState([]);
  const { isAuthenticated, error, user, territoryOrbit } = useSelector((state) => state.auth);
  let [withdrawal, setWithdrawal] = useState();
  let [cabal , setCabal] = useState();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); // Set to 'today' initially
  const [accountOpened, setAccounts] = useState()
  let [customStartDate, setCustomStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [customEndDate, setCustomEndDate] = useState(new Date().toISOString().split('T')[0]);



  
  useEffect(() => {
    const fetchDat = async () => {
      try {
        if (userPermission.permissions.find((permission) => permission.name.includes("Overall"))) {
         
          setIds(territoryOrbit);
          setUserType({ type: "MANAGER", id: territoryOrbit });
          // return { type: "MANAGER", id: territoryOrbit }
          // console.log(userType);
        } else if (userPermission.permissions.find((permission) => permission.name.includes("Territorial"))) {
          setIds(user?.branch?.orbitID);
          setUserType({ type: "MANAGER", id: user?.branch?.orbitID });
          // console.log(userType);
        } else if (userPermission?.permissions?.find((permission) => permission?.name.includes("Branch"))) {
          setIds(user.orbitID)
          setUserType({ type: "RO", id: user?.orbitID });
          // console.log(userType);
        }else{
          setIds(user.orbitID)
          setUserType({ type: "RO", id: user?.orbitID });
        }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

fetchDat();
}, []); 
  
  
  const handleFilterChange = (value, startDate, endDate) => {
    setFilterValue(value);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
    console.log(startDate, endDate)
    console.log(customStartDate, customEndDate)
    // You can perform additional actions based on the selected filter values here
  };
  const handleSelectChange = async(event, selectId) => {
    
    const selectedOption = event;
    const newUserType = await determineUserRole(userPermission?.permissions, selectId, ids);
    console.log(newUserType, selectId, userPermission?.permissions)
    setUserType(newUserType);
    setIds(selectedOption)
    // Now you can use the selectId as needed
    console.log(`Selected option in ${selectId}: ${selectedOption}`);
  };
  

  const determineUserRole = async(permissions, types, ids) => {
    if (!permissions) {
      return null;
    }
    if (types){
      
    if (types == "territories"){
      return { type: "MANAGER", id: ids };
    }else if (types == "branches"){
      return { type: "MANAGER", id: ids };
      }else if (types == "users"){
        return { type: "RO", id: ids};
    } 
    }
  };

  const { submitError, submitForm } = useFormSubmission("Savings API");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
     if (userType || await determineUserRole(userPermission?.permissions)?.type){
      let { submitSuccess, responseBody } = await submitForm("fetchDashboard", {
        user: userType != undefined ? userType.type : await determineUserRole(userPermission?.permissions)?.type,
        type: "SAVINGS",
        startDate: customStartDate,
        endDate: customEndDate,
        ids: userType != undefined ? userType.id : user?.orbitID
      });

     
     
          
    if (submitSuccess) {
        setData(responseBody.data);
        // console.log(responseBody.data)
    }

    setLoading(false);
  }
  };
    fetchData();
  }, [filterValue, customStartDate, customEndDate, ids, userType]);
  return (
    <DashboardLayout>
    <Box>
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} mb={6}>
   
    <Text mb={4} fontSize="11" fontWeight={600}>
  {userPermission?.permissions?.find((permission) => permission.name.includes("Overall")) ? (
      <CustomSelect
      id="territories"
      url="territories/withIDs"
      api="Auth API"
      handleSelectChange={handleSelectChange}
    />
  ) : userPermission?.permissions?.find((permission) => permission.name.includes("Territorial")) ? (
      <CustomSelect
        id="branches"
        url="branches"
        api="Auth API"
        handleSelectChange={handleSelectChange}
      />
  ) : userPermission?.permissions?.find((permission) => permission.name.includes("Branch")) ? (
    <CustomSelect
        id="users"
        url="users/list"
        api="Auth API"
        handleSelectChange={handleSelectChange}
      />
  ) : null}
    </Text>

   <DateFilter
         onFilterChange={handleFilterChange}
         useCustomDateFormat={true} // Set to true if you want to use the custom date format
         numOfDays={null}
       />
   </Grid>
  <TransactionTable dataObject={data} />
 
</Box>

    </DashboardLayout>
  );
};

export default CollectionUsers;
