import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Collapse,
  Icon,
  Image,
  Center,
  useMediaQuery
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdKey } from 'react-icons/md';

function SidebarItem({ item, isActive, sub }) {
 
  const [isOpen, setIsOpen] = useState(false);
  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
     <Link
        as={Button}
        onClick={toggleSubMenu}
        // color={isActive ? "gray.500" : "gray.900"}
        rightIcon={
          item.submenu ? (isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />): ""
        }
        to={item.url}
      >
        <Box 
         bg={isActive ? "#fde428": "none"}  
         _hover={{
          bg: "#fde428",
          color: 'gray.700'
        }}
        display={'flex'}
        borderRadius={10}
        // w={sub ? 175 : 200}
        textAlign="left" // Set the textAlign property to "left"
        color={isActive ? "gray.700" : "#fff"}
        // justifyContent="flex-start" // Add justifyContent property to align content to the left
        px={3}
        py={2}
        >
        <Icon as={item.icon} mr="2" />
        <Text fontSize="11">{item.label}</Text>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} flex="auto">
        {
          item.submenu && (isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />)
        }</Box>
        </Box>
      </Link>

      {item.submenu && (
        <Collapse in={isOpen}>
          <VStack align="start" 
          // ml={{ base: 0, md: 3 }} 
          spacing="1">
            {item.submenu.map((subItem, index) => (
              <SidebarItem
                key={index}
                item={subItem}
                isActive={isActive}
                sub={true}
              />
            ))}
          </VStack>
        </Collapse>
      )}
    </>
  );
}

function Sidebar({ menuItems, isSidebarOpen }) {

  
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const activePage =  window.location.pathname;
 
  const shouldHideSidebar = !isSidebarOpen;
  const userRole = useSelector((state) => state?.auth?.user?.role?.name);
  const userBranch = useSelector((state) => state?.auth?.user?.businessUnit?.name);
  const userUnit = useSelector((state) => state?.auth?.user?.department?.name);
 
  const menuItemsFiltered = menuItems?.filter((item) =>  item?.department == "All" || item?.role?.includes(userRole) && item?.department?.includes(userUnit) || item?.role?.includes(userRole));

  // console.log(shouldHideSidebar)
  return (
    <Box
      bg="darkblue"
      color="white"
      overflowX="hidden"
      w={200}
      
      // px={{ base: 4, md: 4 }}
      minH={{ base: "200vh", md: "100vh" }}
      display={shouldHideSidebar ? "none" : "block"}
      position={isMobile ? 'absolute': 'relative'}
      zIndex={22}
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px", // Adjust the scrollbar width as needed
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(255, 255, 255, 0.3)", // Adjust the scrollbar color and transparency
          borderRadius: "4px", // Adjust the scrollbar thumb's border radius
        },
      }}
      className='no-print'
    >
      <Center p="4" >
        <Image
          src="/images/mfb-white.png"
          alt="Logo"
          maxH="80px"
        />
      </Center>

      <VStack 
       align="stretch" px={3}>
        {menuItemsFiltered?.map((item, index) => (
          <SidebarItem
            key={index}
            item={item}
            isActive={activePage == item.url}
            px={3}
          />
        ))}
      </VStack>
      <Box px={3}   fontSize={11} ml={3} mt="100%">

      <Link   
        rightIcon={MdKey} 
        to="/change_password"  
        fontSize={11} 
        
        > 
        <Box  
        
        display={'flex'}
        borderRadius={10}
        
        textAlign="left">Change Password </Box></Link>
      </Box>
    </Box>
  );
}

export default Sidebar;
