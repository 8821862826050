// src/components/Pagination.js
import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const PaginationButton = ({ pageNumber, currentPage, onClick, color, bg }) => (
  
  <Button
    height={7}
    fontSize={9}
    border={'1px solid #6c757d'}
    _hover={{ color: '#6c757d', bg: 'transparent' }}
    onClick={() => onClick(pageNumber)}
    bg={currentPage === pageNumber ? bg : 'transparent'}
    color={currentPage === pageNumber ? color : '#6c757d'}
  >
    {pageNumber}
  </Button>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const configs = useSelector((state)=> state.auth.config)
  console.log(totalPages)
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const renderPaginationButtons = React.useMemo(() => {
    const firstPage = 1;
    const lastPage = totalPages;

    const paginationButtons = [];

    // Show the first page
    paginationButtons.push(
      <PaginationButton
        key="first"
        pageNumber={firstPage}
        currentPage={currentPage}
        onClick={handlePageChange}
      />
    );

    // Check if there is only one page
    if (totalPages === 1) {
      return paginationButtons;
    }

    // Show the middle pages
    const middlePages = Math.min(7, totalPages - 2);
    const startMiddle = Math.max(firstPage + 1, currentPage - Math.floor(middlePages / 2));
    const endMiddle = Math.min(lastPage - 1, startMiddle + middlePages - 1);

    paginationButtons.push(
      ...Array.from({ length: middlePages }, (_, index) => (
        <PaginationButton
          key={startMiddle + index}
          pageNumber={startMiddle + index}
          currentPage={currentPage}
          onClick={handlePageChange}
          color={configs?.primaryColor}
          bg={configs?.primaryBg}
        />
      ))
    );

    // Show the last page
    paginationButtons.push(
      <PaginationButton
        key="last"
        pageNumber={lastPage}
        currentPage={currentPage}
        onClick={handlePageChange}
        color={configs?.primaryColor}
          bg={configs?.primaryBg}
      />
    );

    return paginationButtons;
  }, [currentPage, totalPages, onPageChange]);

  // Check if there are more than one page to show the "Next" button
  const showNextButton = totalPages > 1;

  return (
    <Flex mt={4}>
      <Button
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        fontSize={9}
        height={7}
      >
        Previous
      </Button>
      {renderPaginationButtons}
      {showNextButton && (
        <Button
          height={7}
          fontSize={9}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      )}
    </Flex>
  );
};

export default Pagination;
