import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 9,
    marginBottom: 10,
  },
  table: {
    width: '100%',
    marginBottom: '10px',
    overflowX: 'auto',
    color: '#6c757d',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontSize: 9
  },
  tableRow: {
   margin: 'auto',
    flexDirection: 'row',
    // backgroundColor: '#f7f7f9',
    fontWeight: 'bold',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #dee2e6',
    width:'100%',
    fontSize: 9,
  },
  tableCell: {
    padding: '8px',
    // borderBottom: '1px solid #dee2e6',
    width:'100%',
    fontSize: 9,
  },
});

const PDFRenderer = ({ data, headers }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Table Data</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {headers.map((header, index) => (
                <View key={index} style={styles.tableCell}>
                  <Text>{header.label}</Text>
                </View>
              ))}
            </View>
            {data.map((item, rowIndex) => (
              <View key={rowIndex} style={styles.tableRow}>
                {headers.map((header, index) => (
                  <View key={index} style={styles.tableCell}>
                    <Text>{item[header.key]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFRenderer;
