import React, { useState, useEffect } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import DataTableEdit from '../../common/components/DataTableEdit';
import DashboardLayout from '../../common/components/DashboardLayout';
import useFetch from '../../common/hooks/useFetch';
import DynamicTabForm from '../../common/components/DynamicTab';
import useFormSubmission from '../../common/hooks/useFormSubmission';

const SavingsTarget = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Savings API");  
  const [message, setMessage] = useState();
  const { data: productData, loading: productLoading, error: productError, fetchData: fetchProduct } = useFetch("Savings API");
  const { data: caseloadData, loading: caseloadLoading, error: caseloadError, fetchData: fetchCaseload } = useFetch("Savings API");
  const { data: visitationData, loading: visitationLoading, error: visitationError, fetchData: fetchVisitation } = useFetch("Savings API");
  const { data: dmotargetData, loading: dmotargetLoading, error: dmotargetError, fetchData: fetchDMOTarget } = useFetch("Savings API");
  const { data: activesaversData, loading: activesaversLoading, error: activesaversError, fetchData: fetchActiveSavers } = useFetch("Savings API");
  const { data: dailymobilizationData, loading: dailymobilizationLoading, error: dailymobilizationError, fetchData: fetchDailyMobilization } = useFetch("Savings API");

  useEffect(() => {
    fetchProduct("productCategory");
    fetchCaseload("caseload");
    fetchVisitation("visitation");
    fetchDMOTarget("dmotarget");
    fetchActiveSavers("activesavers");
    fetchDailyMobilization("dailymobilization");
  }, [productData, caseloadData, visitationData, dmotargetData, dailymobilizationData]);

  const productHeaders = [
    { label: 'Product Type', key: 'type' },
    { label: 'Daily Mobilization', key: 'dailyMobilization' },
    { label: 'Contribution (%)', key: 'percentageContribution' }
  ];

  const caseloadHeaders = [
    { label: 'Service Length (Months)', key: 'serviceLength' },
    { label: 'Minimum Caseload', key: 'minimumCaseLoad' }
  ];

  const dmotargetHeaders = [
    { label: 'Service Length (Months)', key: 'lengthOfService' },
    { label: 'Account Opening Target', key: 'accountOpeningTarget' }
  ];

  const activesaversHeaders = [
    { label: 'Product Category', key: 'productCategory' },
    { label: 'Contribution (%)', key: 'contributionPercentage' },
    {
      label: 'Service Length', key: 'serviceLengths', 
      headers: [{ label: 'Customers', key: 'numberOfCustomers' },
      { label: 'Service Length', key: 'lengths' }]
      // headerRender: () => (
      //   <tr>
      //     <th style={{ padding: "5px" }}>Customers</th>
      //     <th style={{ padding: "5px" }}>Service Length (Months)</th>
      //   </tr>
      // ),
      // render: (item) => (
      //   <table>
      //     <thead>
      //       {activesaversHeaders[2].headerRender()}
      //     </thead>
      //     <tbody>
      //       {item.serviceLengths.map((length, index) => (
      //         <tr key={index}>
      //           <td style={{ padding: "5px" }}>{length.numberOfCustomers}</td>
      //           <td style={{ padding: "5px" }}>{length.lengths}</td>
      //         </tr>
      //       ))}
      //     </tbody>
      //   </table>
      // ),
    },
  ];

  const dailyMobilizationHeaders = [
    { label: 'Service Length', key: 'lengthOfService' },
    {
      label: 'Mobilization', key: 'mobilization',
      headers: [{ label: 'Region', key: 'region' },
      { label: 'Min', key: 'min' },
      { label: 'Max', key: 'max' }]
     
    },
  ];

  const visitationHeaders = [
    { label: 'Category', key: 'category' },
    { label: 'Mobilization', key: 'dailyMobilization' },
    { label: 'Product Mix (%)', key: 'productMixPercentage' },
    {
      label: 'Service Length', key: 'serviceLengths',
      headers: [{ label: 'Service Length', key: 'lengths' },
      { label: 'Visitation', key: 'visitation' }]
    }
  ];

  const handleSave = async(id, updatedData, name) => {
    
    const { submitSuccess, responseBody } = await submitForm(name+"/"+id, updatedData);
    if (submitSuccess){
        setMessage(responseBody.message)
    }
  };

  const tabs = {
    header: [
      'Collection Demography',
      'Case Load',
      'Account Opening',
      'Active Saver Mix',
      'Daily Mobilization',
      'Visitation'
    ],
    content: [
      <>
        {/* <DemographyForm /> */}
        <DataTableEdit
          data={productData}
          headers={productHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={productLoading}
          onSave={handleSave}
          name={'productCategory'}
        />
      </>,
      <>
        {/* <CaseLoadForm /> */}
        <DataTableEdit
          data={caseloadData}
          headers={caseloadHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={caseloadLoading}
          onSave={handleSave}
          name={'caseload'}
        />
      </>,
      <>
        {/* <AccountOpening /> */}
        <DataTableEdit
          data={dmotargetData}
          headers={dmotargetHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={dmotargetLoading}
          onSave={handleSave}
          name={'dmotarget'}
        />
      </>,
      <>
        {/* <SaveMix /> */}
        <DataTableEdit
          data={activesaversData}
          headers={activesaversHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={activesaversLoading}
          onSave={handleSave}
          name={'activesavers'}
        />
      </>,
      <>
        {/* <DailyMobilization /> */}
        <DataTableEdit
          data={dailymobilizationData}
          headers={dailyMobilizationHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          onSave={handleSave}
          name={'dailymobilization'}
          loading={dailymobilizationLoading}
        />
      </>,
      <>
        {/* <Visitation /> */}
        <DataTableEdit
          data={visitationData}
          headers={visitationHeaders}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={visitationLoading}
          onSave={handleSave}
          name={'visitation'}
        />
      </>,
    ],
  };

  return (
    <DashboardLayout>
    {(productLoading || dailymobilizationLoading) ?
      <Spinner />:(
      <Box p={5}>
        <Text color={"green"}>{message && (<Text>{message}</Text>)} </Text>
        <Text color={"red"}>{submitError && (<Text>{submitError}</Text>)} </Text>
        <DynamicTabForm tabs={tabs} />
      </Box>)}
    </DashboardLayout>
  );
};

export default SavingsTarget;
