import authService from './authService';

export const login = (credentials) => async (dispatch) => {
  try {
    const user = await authService.login(credentials);
    console.log(user, "login")
    if (user) {
      const data = await authService.user(user?.access_token);
      console.log(data, "profile")
      if (data) {
        const team = await authService.team(user?.access_token, data);
        console.log(team, "team2")
        if (Array.isArray(team)){
        const territory = await authService.territory(user?.access_token, data.branch?.territory?.id);

        console.log(team, territory);

        // Dispatch success action with appropriate data
        dispatch(loginSuccess({ user: data, token: user.access_token, territoryOrbit: territory, team: team }));
        }
      } else {
        // Handle case where data is undefined
        dispatch(loginFailure("User data not available."));
      }
    } else {
      // Handle case where user is undefined
      dispatch(loginFailure("Login failed. User not found."));
    }
  } catch (error) {
    // Dispatch failure action with error message
    dispatch(loginFailure(error.message));
  }
};


export const logout = () => ({ type: 'LOGOUT' });

const loginSuccess = (user) => ({
  type: 'LOGIN_SUCCESS',
  payload: user,
});

const loginFailure = (error) => ({
  type: 'LOGIN_FAILURE',
  payload: error,
});
