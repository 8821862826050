import React, { useState } from 'react';
import { format, subDays, subWeeks, subMonths } from 'date-fns';
import {
  Box,
  Button,
  Flex,
  Select,
  Spacer,
  Text,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';

const DateFilter = ({ onFilterChange, useCustomDateFormat, numOfDays }) => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  // const [selectedFilter2, setSelectedFilter2] = useState('');

  const handleFilterChange = (value) => {
    
      setSelectedFilter(value);
    

    let startDate = '';
    let endDate = '';

    let today = new Date();

    switch (value) {
      case 'today':
        startDate = format(today, "yyyy-MM-dd");
        endDate = format(today, "yyyy-MM-dd");
        if (numOfDays) numOfDays(1);
        break;
      case 'yesterday':
        startDate = format(subDays(today, 1), "yyyy-MM-dd");
        endDate = format(subDays(today, 1), "yyyy-MM-dd");
        if (numOfDays) numOfDays(1);
        break;
      case 'last30':
        startDate = format(subDays(today, 29), "yyyy-MM-dd");
        endDate = format(today, "yyyy-MM-dd");
        if (numOfDays) numOfDays(30);
        break;
      case 'lastWeek':
        startDate = format(subWeeks(today, 1), "yyyy-MM-dd");
        endDate = format(today, "yyyy-MM-dd");
        if (numOfDays) numOfDays(7);
        break;
      case 'lastMonth':
        startDate = format(subMonths(today, 1), "yyyy-MM-dd");
        endDate = format(today, "yyyy-MM-dd");
        if (numOfDays) numOfDays(30);
        break;
      default:
        // For 'custom2' and other cases, no date conversion is needed
        break;
    }

    // Pass the selected value and custom date range to the parent component
    if (value !== 'custom2') {
      onFilterChange(value, startDate, endDate);
    }
  };

  const handleCustomApply = () => {
    if (customStartDate && customEndDate) {
      onFilterChange('custom', customStartDate, customEndDate);
    }
  };

  return (
    <Box px={2}>
      <Flex align="center">
        <Text fontSize={12} mr={1}>Filter by Date:</Text>
        <Select
          background={'white'}
          fontSize={12}
          placeholder="Select a date range"
          value={selectedFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last30">Last 30 Days</option>
          <option value="lastWeek">Last Week</option>
          <option value="lastMonth">Last Month</option>
          <option value="custom2">Custom Date Range</option>
        </Select>
        {selectedFilter === 'custom2' && (
          <>
            <Spacer />
            <InputGroup size="sm" bg="white">
              <Input
                type="date"
                placeholder="Start Date"
                value={customStartDate}
                fontSize={12}
                onChange={(e) => setCustomStartDate(e.target.value)}
              />
              <Input
                type="date"
                placeholder="End Date"
                value={customEndDate}
                fontSize={12}
                onChange={(e) => setCustomEndDate(e.target.value)}
              />
              <InputRightAddon>
                <Button
                  size="sm"
                  color="white"
                  background="darkblue"
                  fontSize={12}
                  onClick={handleCustomApply}
                >
                  Apply
                </Button>
              </InputRightAddon>
            </InputGroup>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default DateFilter;
