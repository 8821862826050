import { MdDashboard, MdShoppingCart, MdAssignment, 
  MdPeople, MdSettings, MdSave, MdMoney,
MdBusiness, MdTrendingUp, MdAreaChart } from "react-icons/md"; // Import Material Icons

const menuItems = [
  {
    label: 'Dashboard',
    icon: MdDashboard, // Use the Material Icon component
    url: '/dashboard' || '/',
    role: ["Administrator"],
    department: ["All"]
  },
  {
    label: 'Trend Analysis',
    icon: MdAreaChart, // Use the Material Icon component
    url: '/trend',
    role: ["Territorial Coordinator", "Savings Officer", "Group Head", "Team Lead", "Group Managing Director","Managing Director"],
    department: ["All"]
  },{
    label: 'Savings Report',
    icon: MdMoney, // Use the Material Icon component
    url: '/report',
    role: ["Territorial Coordinator", "Savings Officer", "Group Head", "Team Lead", "Group Managing Director","Managing Director"],
    department: ["All"]
  },{
    label: 'Mass Marketing',
    icon: MdBusiness, // Use the Material Icon component
    url: '/mass',
    role: ["Territorial Coordinator", "Savings Officer", "Team Lead", "Group Head", "Group Managing Director","Managing Director"],
    department: ["SAVINGS GROUP", "Human Resources"]
  },
  {
    label: 'Set Target',
    icon: MdAssignment, // Use the Material Icon component
    url: '/savings-target',
    role: ["Territorial Coordinator", "Administrator", "Group Head"],
    department: ["SAVINGS GROUP"]
  },{
    label: 'Budget',
    icon: MdAssignment, // Use the Material Icon component
    url: '/budget',
    role: ["Territorial Coordinator", "Administrator", "Group Head", "Strategy Officer"],
    department: ["SAVINGS GROUP"]
  },
   {
    label: 'Savings',
    icon: MdSave, // Use the Material Icon component
    url: '#',
    role: ["Administrator"],
    department: ["IT"],
    submenu: [
      {
        label: 'Savings Dashboard',
        icon: MdAssignment, // Use the Material Icon component
        url: '/savings',
      },
      {
        label: 'Trend Analysis',
        icon: MdAreaChart, // Use the Material Icon component
        url: '/trend',
      },{
        label: 'Savings Report',
        icon: MdMoney, // Use the Material Icon component
        url: '/report',
      },{
        label: 'Mass Marketing',
        icon: MdBusiness, // Use the Material Icon component
        url: '/mass',
      },
      {
        label: 'Set Target',
        icon: MdAssignment, // Use the Material Icon component
        url: '/savings-target',
      }
    ]
  },
  {
    label: 'Loans',
    icon: MdBusiness, // Use the Material Icon component
    url: '#',
    submenu: [
      {
        label: 'SME Loans',
        icon: MdAssignment, // Use the Material Icon component
        url: '/loans/sme',
      },
      {
        label: 'Retail Loans',
        icon: MdAssignment, // Use the Material Icon component
        url: '/loans/retail',
      },
      {
        label: 'RSME Loans',
        icon: MdAssignment, // Use the Material Icon component
        url: '/loans/rsme',
      },
      ,
      {
        label: 'Consumer and Personal Finance Loans',
        icon: MdAssignment, // Use the Material Icon component
        url: '/loans/consumer',
      },
    ],
    role: ["Administrator"],
    department: ["IT", "Savings Group"]
  },
  {
    label: 'Control Management',
    icon: MdSettings, // Use the Material Icon component
    url: '#',
    submenu: [
      {
        label: 'User Management',
        icon: MdPeople, // Use the Material Icon component
        url: '/users',
      },
      {
        label: 'Branch Management',
        icon: MdAssignment, // Use the Material Icon component
        url: '/branches',
      },
      {
        label: 'Roles Management',
        icon: MdAssignment, // Use the Material Icon component
        url: '/roles',
      },
      {
        label: 'department Management',
        icon: MdAssignment, // Use the Material Icon component
        url: '/departments',
      },{
        label: 'Permission Management',
        icon: MdAssignment, // Use the Material Icon component
        url: '/permissions',
      },
    ],
    role: ["Administrator"],
    department: ["IT", "Savings Group"]
  },
  // Add more menu items as needed
];

export default menuItems;
