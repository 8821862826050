// src/hooks/useApiUrls.js
import { useEffect, useState } from 'react';
import data from "../../apiData.json";

const useApiUrls = () => {
    let apiUrls = data.apis;
   
  const getUrlByName = (apiName) => {
    const api = apiUrls.find((api) => api.name.toLowerCase() === apiName.toLowerCase());
    
    return api?.url;
  };

  return { apiUrls, getUrlByName };
};

export default useApiUrls;
