import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react';

const DynamicForm = ({ fields, formDatas, handleInputChange }) => {
  const [groupedFields, setGroupedFields] = useState({});
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 768);
  const fontSize = '13px';


  // const handleInputChange = (e) => {
  //   console.log(e, "single");
  //   handleInputChange(e); // Call the parent component's handler
    
  // };

  const handleGroupChange = (e, sectionIndex, groupIndex) => {
    const { name, value } = e.target || {};
   
    if (!name) return; // Check if 'name' exists to avoid errors

    setGroupedFields((prevGroupedFields) => {
      const updatedGroups = [...(prevGroupedFields[sectionIndex] || [])];
      // Adjust groupIndex here by subtracting 1
      const updatedGroupFields = { ...updatedGroups[groupIndex - 1] };
      updatedGroupFields.fields = (updatedGroupFields.fields || []).map((field) => {
        if (field.name === name) {
          return { ...field, [name]: value };
        }
        return field;
      });

      // Adjust groupIndex here by subtracting 1
      updatedGroups[groupIndex - 1] = updatedGroupFields;
      console.log(updatedGroups);
      return { ...prevGroupedFields, [sectionIndex]: updatedGroups };
    });

    const updatedFormDatas = { ...formDatas, [name]: value };
    console.log(updatedFormDatas);
    handleInputChange({ name: name, value: value, groupIndex: groupIndex });
  };
  
  const handleRemoveGroup = (sectionIndex, groupIndex) => {
    setGroupedFields((prevGroupedFields) => {
      const updatedGroups = [...(prevGroupedFields[sectionIndex] || [])];
      
      // Adjust groupIndex here by subtracting 1
      updatedGroups.splice(groupIndex - 1, 1);
  
      // Update the corresponding data in formDatas
      const updatedFormDatas = { ...formDatas };
      for (const fieldName in updatedFormDatas) {
        if (Array.isArray(updatedFormDatas[fieldName])) {
          // Adjust groupIndex here by subtracting 1
          updatedFormDatas[fieldName].splice(groupIndex, 1);
        }
      }
  
      return { ...prevGroupedFields, [sectionIndex]: updatedGroups };
    });
  };
  
  

  const handleAddGroup = (sectionIndex) => {
    setGroupedFields((prevGroupedFields) => {
      const updatedGroups = [...(prevGroupedFields[sectionIndex] || [])];
      const newGroup = {
        ...fields[sectionIndex],
        fields: (fields[sectionIndex]?.fields || []).map((field) => ({
          ...field,
          [field.name]: '',
        })),
      };
      updatedGroups.push(newGroup);
      return { ...prevGroupedFields, [sectionIndex]: updatedGroups };
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!fields || !Array.isArray(fields)) {
    // Handle the case where fields is undefined or not an array
    return null; // or return some default content or an error message
  }

  return (
    <div>
      {fields.map((section, sectionIndex) => (
        
        <div key={`section-${sectionIndex}`}>
          <Box
            p={1}
            style={{
              marginX: isWideScreen ? '5px' : '5px',
              marginBottom: isWideScreen ? '5px' : '5px',
              width: 'auto',
            }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Box fontWeight="bold" fontSize={fontSize}>
                {section.section}
              </Box>
            </Flex>
            <br />
            {section.fields && Array.isArray(section.fields) && section.fields.map((field, fieldIndex) => (
              <Box
                key={fieldIndex}
                style={{
                  display: field.type === 'textarea' ? 'block' : 'inline-block',
                  marginRight: isWideScreen ? '15px' : 0,
                  marginBottom: isWideScreen ? '5px' : '5px',
                  marginTop: '15px',
                  width: isWideScreen ? field.width : 'auto',
                }}
              >
                <FormControl>
                  {field.label && (
                    <FormLabel fontSize={fontSize} fontWeight={400} color="#6c757d">
                      {field.label}
                    </FormLabel>
                  )}

                  {field.type === 'select' ? (
                    <Select
                     className={field.class} id={field.id}
                      name={field.name}
                      style={{ fontSize }}
                      border={'1px solid #ced4da'}
                      onChange={section.increment ? (e) => handleGroupChange(e, sectionIndex, 0) : handleInputChange}
                      // value={Array.isArray(formDatas[field.name]) ? formDatas[field.name][0] || '' : ''}
                    >
                      {field.options && Array.isArray(field.options) && field.options.map((option, optionIndex) => (
                        <option key={optionIndex}>{option}</option>
                      ))}
                    </Select>
                  ) : field.type === 'textarea' ? (
                    <Textarea
                      style={{ fontSize }}
                     className={field.class} id={field.id}
                      border={'1px solid #ced4da'}
                      name={field.name}
                      // value={Array.isArray(formDatas[field.name]) ? formDatas[field.name][0] || '' : ''}
                      onChange={section.increment ? (e) => handleGroupChange(e, sectionIndex, 0) : handleInputChange}
                    />
                  ) : (
                    <Input
                     className={field.class} id={field.id}
                      name={field.name}
                      style={{ fontSize }}
                      border={'1px solid #ced4da'}
                      type={field.type}
                      onChange={section.increment ? (e) => handleGroupChange(e, sectionIndex, 0) : handleInputChange}
                      // value={Array.isArray(formDatas[field.name]) ? formDatas[field.name][0] || '' : ''}
                    />
                  )}
                </FormControl>
              </Box>
            ))}

            {groupedFields[sectionIndex]?.map((groupFields, groupIndex) => (
              <div key={`grouped-field-${sectionIndex}-${groupIndex + 1}`}>
                {groupFields.fields && Array.isArray(groupFields.fields) && groupFields.fields.map((subField, subIndex) => (
                  <Box
                    key={subIndex}
                    style={{
                      display: subField.type === 'textarea' ? 'block' : 'inline-block',
                      marginRight: isWideScreen ? '15px' : 0,
                      marginBottom: isWideScreen ? '5px' : '5px',
                      marginTop: '15px',
                      width: isWideScreen ? subField.width : 'auto',
                    }}
                  >
                    <FormControl>
                      {subField.label && (
                        <FormLabel fontSize={fontSize} fontWeight={400} color="#6c757d">
                          {subField.label}
                        </FormLabel>
                      )}

                      {subField.type === 'select' ? (
                        <Select
                          className={subField.class}
                          name={subField.name}
                          value={Array.isArray(formDatas[subField.name]) ? formDatas[subField.name][groupIndex + 1] || '' : ''}
                          style={{ fontSize }}
                          id={`${subField.id}-${groupIndex}`}
                          onChange={(e) => handleGroupChange(e, sectionIndex, groupIndex + 1)}
                          border={'1px solid #ced4da'}
                        >
                          {subField.options && Array.isArray(subField.options) && subField.options.map((option, optionIndex) => (
                            <option key={optionIndex}>{option}</option>
                          ))}
                        </Select>
                      ) : subField.type === 'textarea' ? (
                        <Textarea
                          style={{ fontSize }}
                          className={subField.class}
                          name={subField.name}
                          border={'1px solid #ced4da'}
                          value={Array.isArray(formDatas[subField.name]) ? formDatas[subField.name][groupIndex + 1] || '' : ''}
                          id={`${subField.id}-${groupIndex}`}
                          onChange={(e) => handleGroupChange(e, sectionIndex, groupIndex + 1)}
                        />
                      ) : (
                        <Input
                          className={subField.class}
                          name={subField.name}
                          style={{ fontSize }}
                          value={Array.isArray(formDatas[subField.name]) ? formDatas[subField.name][groupIndex + 1] || '' : ''}
                          id={`${subField.id}-${groupIndex}`}
                          border={'1px solid #ced4da'}
                          type={subField.type}
                          onChange={(e) => handleGroupChange(e, sectionIndex, groupIndex + 1)}
                        />
                      )}
                    </FormControl>
                  </Box>
                ))}
               
                <Flex>
                  <Button
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{
                      color: '#edf2f7',
                      backgroundColor: '#fde428',
                      borderColor: '#3f80ea',
                      fontSize: 25,

                    }}
                    size="sm"
                    onClick={() => handleRemoveGroup(sectionIndex, groupIndex + 1)}
                  >
                    -
                  </Button>
                </Flex>
              </div>
            ))}

            {section.increment && (
              <Box style={{ float: 'right' }}>
                <Button
                  style={{
                    color: '#fff',
                    backgroundColor: '#0e0ead',
                    borderColor: '#3f80ea',
                  }}
                  size="sm"
                  onClick={() => handleAddGroup(sectionIndex)}
                >
                  +
                </Button>
              </Box>
            )}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default DynamicForm;
