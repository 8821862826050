import React from 'react';
import { Box, Flex, Text, Spacer, Button, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import Logout from './Logout';

const TopBar = ({ onToggleSidebar }) => {
  return (
    <Box 
      className='no-print'
      bg="#fde428" 
      color="white"
      px="4" 
      py={2} 
      shadow="md"
     >
      <Flex align="center">
        {/* Hamburger menu button for sidebar */}
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Toggle Sidebar"
          mr="4"
          display={{ base: 'block', md: 'block' }}
          onClick={onToggleSidebar} // Call the provided callback to toggle the sidebar
        />

   

        <Spacer />

        {/* Example user profile button */}
        <Button variant="link">
          {/* <Link to="/profile">Profile</Link> */}
        </Button>
        <Logout />
      </Flex>
    </Box>
  );
}

export default TopBar;
