import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import useFormSubmission from '../hooks/useFormSubmission';

const StepFormLayout = ({ children, formData, setFormData, stepUrls, returns }) => {
  const [step, setStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const {
    isSubmitting,
    submitError,
    submitForm,
  } = useFormSubmission();
  let [submitSuccess, setSubmitSuccess] = useState(false);
  let [responseBody, setResponseBody] = useState("");
  const [dynamicStepUrls, setDynamicStepUrls] = useState(() => [...stepUrls]);

  useEffect(() => {
    setShowAlert(false);
  }, [step]);

  const handleNextStep = async () => {
    try {
      const apiUrl = dynamicStepUrls[step];
      let { submitSuccess, responseBody } = await submitForm(apiUrl, formData);
      setSubmitSuccess(submitSuccess);
      setResponseBody(responseBody);
      console.log(submitSuccess, responseBody?.status)
      const returnData = returns[step];
      if (submitSuccess || (responseBody && responseBody.status == 200)) {
        console.log("suceeded");
        if (returnData) {
          for (const key of returnData) {
            if (responseBody.hasOwnProperty(key)) {
              setFormData((prevFormData) => ({
                ...prevFormData,
                [key]: responseBody[key],
              }));
            }
          }
        }

        if (step < children.length - 1) {
          setStep((prevStep) => prevStep + 1);
        } else {
          setShowAlert(true);
        }
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setShowAlert(true);
    }
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <Box>
      <VStack spacing={4}>
        <HStack spacing={2}>
          {children.map((_, index) => (
            <Box
              key={index}
              w="20px"
              h="20px"
              borderRadius="40%"
              bg={index === step ? 'blue.500' : 'gray.300'}
            />
          ))}
        </HStack>

        {children[step]}

        <div>
          {showAlert && submitSuccess && children.length - 1 === step && (
            <Alert status="success" mt={4}>
              <AlertIcon />
              <AlertTitle mr={2}>Form submitted successfully!</AlertTitle>
              {responseBody && (
                <Box>
                  <Text>Response Body:</Text>
                  <pre>{JSON.stringify(responseBody, null, 2)}</pre>
                </Box>
              )}
              <CloseButton
                onClick={() => setShowAlert(false)}
                position="absolute"
                right="8px"
                top="8px"
                aria-label="Close Success Alert"
              />
            </Alert>
          )}

          {showAlert && !submitSuccess && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{submitError}</AlertTitle>
              <CloseButton
                onClick={() => setShowAlert(false)}
                position="absolute"
                right="8px"
                top="8px"
                aria-label="Close Error Alert"
              />
            </Alert>
          )}
        </div>

        <HStack spacing={4}>
          {step > 0 && (
            <Button
              colorScheme="blue"
              variant="outline"
              size="sm"
              onClick={handlePrevStep}
              aria-label="Previous Step"
            >
              Previous
            </Button>
          )}
          <Button
            colorScheme="blue"
            size="sm"
            onClick={handleNextStep}
            isLoading={isSubmitting}
            aria-label="Next Step"
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default StepFormLayout;
