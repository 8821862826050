// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
// import loggerMiddleware from 'redux-logger'; // Example middleware, you can add others

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  // Add other options as needed
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

export default store;
