import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Grid,
  Flex,
  useMediaQuery,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import Pagination from './Pagination';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import PDFRenderer from './pdfRenderer';

const DataTableEdit = ({
  data,
  headers,
  buttons,
  page,
  onPageChange,
  pageSize,
  totalItems,
  loading,
  search,
  exports,
  pagination,
  onSave,
  name
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(max-width: 1023px)")[0];

  const [clickedItem, setClickedItem] = useState(null);
  const [visibleMenuRowId, setVisibleMenuRowId] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});

  const showMenu = (item) => {
    setClickedItem(item);
    setVisibleMenuRowId(item?._id);
  };

  const hideMenu = () => {
    setClickedItem(null);
    setVisibleMenuRowId(null);
  };

  const handleOpen = (item) => {
    setClickedItem(item);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    const lowercasedQuery = searchQuery?.toLowerCase();
    const filtered = data?.filter((item) =>
      headers.some((header) =>
        item[header.key]?.toString()?.toLowerCase()?.includes(lowercasedQuery)
      )
    );
    setFilteredData(filtered);
  }, [searchQuery, data, headers]);

  const getNestedPropertyValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;

    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return ''; // Or handle the case when a nested property is missing
      }
    }

    return value;
  };

  const tableStyles = {
    width: '100%',
    marginBottom: '1rem',
    overflowX: 'auto',
    color: '#6c757d',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontSize: ".825rem",
  };

  const thStyles = {
    fontWeight: 'bold',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #dee2e6',
  };

  const tdStyles = {
    padding: '4px',
    borderBottom: '1px solid #dee2e6',
    fontSize: "11px"
  };
const otherStyle = {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '2px'
}

  const handleEditClick = (rowId, rowData) => {
    setEditRowId(rowId);
    setEditRowData(rowData);
  };

  const handleSaveClick = (rowId) => {
    onSave(rowId, editRowData, name); // Call the onSave prop with the row ID and edited data
    setEditRowId(null);
    setEditRowData({});
  };

  const handleInputChange = (e, key, nestedKey = null, nestedIndex = null) => {
    if (nestedKey !== null && nestedIndex !== null) {
      setEditRowData((prevData) => {
        const updatedNestedArray = prevData[key].map((item, index) =>
          index === nestedIndex ? { ...item, [nestedKey]: e.target.value } : item
        );
        return { ...prevData, [key]: updatedNestedArray };
      });
    } else {
      setEditRowData({
        ...editRowData,
        [key]: e.target.value,
      });
    }
  };

  return (
    <Box overflowX="auto" bg={'#fff'} color={'#6c757d'} py={5} px={3}>
      <Grid templateColumns={isMobile ? "1fr" : exports == true && search == true ? "repeat(2, 1fr)" : "1fr"}>
        {(exports == true) && (
          <Flex>
            <Button
              mx={1}
              key="export-pdf"
              size={isMobile ? 'xs' : 'sm'}
              colorScheme="blue"
              style={{
                display: "inline-block",
                fontWeight: 400,
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
                verticalAlign: "middle",
                userSelect: "none",
                padding: ".25rem .7rem",
                fontSize: "11px",
                lineHeight: "1.625",
                borderRadius: ".2rem",
                transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
              }}>
              <PDFDownloadLink
                document={<PDFRenderer data={filteredData || []} headers={headers} />}
                fileName="table.pdf">
                {({ blob, url, loading, error }) => loading ? 'Loading document...' : 'PDF'}
              </PDFDownloadLink>
            </Button>

            <Button
              key="print"
              size={isMobile ? 'xs' : 'sm'}
              onClick={() => window.print()}
              style={{
                display: "inline-block",
                fontWeight: 400,
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
                verticalAlign: "middle",
                userSelect: "none",
                padding: ".25rem .7rem",
                fontSize: "11px",
                lineHeight: "1.625",
                borderRadius: ".2rem",
                transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
              }}>
              Print
            </Button>

            <Button
              mx={1}
              key="export-csv"
              size={isMobile ? 'xs' : 'sm'}
              style={{
                display: "inline-block",
                fontWeight: 400,
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
                verticalAlign: "middle",
                userSelect: "none",
                padding: ".25rem .7rem",
                fontSize: "11px",
                lineHeight: "1.625",
                borderRadius: ".2rem",
                transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
              }}>
              <CSVLink
                data={filteredData || []}
                headers={headers}
                filename="table.csv">
                CSV
              </CSVLink>
            </Button>
          </Flex>
        )}
        {(search == true) && (
          <Flex justifyContent="flex-end">
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              width={"70%"}
              onChange={(e) => setSearchQuery(e.target.value)}
              mb={1}
              bg={"#F6F6FE"}
              fontSize={12}
              color={'#6c757d'}
              _placeholder={{ color: '#6c757d' }}
            />
          </Flex>
        )}
      </Grid>
      <Table width={"100%"} variant="simple" style={tableStyles} mt={((exports == true) || (search == true)) ? 4 : 0}>
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th key={header.key} style={thStyles}>
                {header?.headers ?  header?.headers.map((ele)=><Td>{ele.label}</Td>):header?.label}
              </Th>
            ))}
            {buttons?.length !== 0 && (
              <Th style={thStyles} textAlign={'center'} justifyContent={'center'}>Actions</Th>
            )}
          </Tr>
        </Thead>
        <Tbody fontSize={13}>
          {loading ? (
            <Tr>
              <Td colSpan={headers?.length + 1} style={tdStyles} textAlign="center">
                <Spinner size="sm" />
              </Td>
            </Tr>
          ) : filteredData && filteredData?.length > 0 ? (
            filteredData.map((item) => (
              <Tr key={item._id}>
                {headers.map((header) => (
                  <Td key={`${item._id}-${header.key}`} style={tdStyles}>
                    {editRowId === item._id ? (
                      Array.isArray(item[header?.key]) ? (
                        item[header.key]?.map((nestedItem, index) => (
                          <Box key={index} mb={2} gap={2} style={otherStyle}>
                            {header?.headers?.map((nestedHeader) => (
                              <Input
                                key={nestedHeader.key}
                                size="sm"
                                value={getNestedPropertyValue(editRowData, `${header.key}.${index}.${nestedHeader.key}`) || ''}
                                onChange={(e) => handleInputChange(e, header.key, nestedHeader.key, index)}
                              />
                            ))}
                          </Box>
                        ))
                      ) : (
                        <Input
                          size="sm"
                          value={editRowData[header.key] || ''}
                          onChange={(e) => handleInputChange(e, header.key)}
                        />
                      )
                    ) : (
                      header.key === 'status' ? (
                        <Badge variant="subtle" colorScheme={
                          item[header.key]?.toLowerCase() === header?.type1?.toLowerCase() ?
                            'green' :
                            item[header.key]?.toLowerCase() === header?.type2?.toLowerCase() ? 'indigo' :
                              item[header.key]?.toLowerCase() === header?.type3?.toLowerCase() ? 'gray' :
                                item[header.key]?.toLowerCase() === header?.type4?.toLowerCase() ? 'red' : ''
                        }>
                          {item[header.key]}
                        </Badge>
                      ) : header.key === 'approval' ? (
                        item[header.key] == false ? <span>N</span> : <span>Y</span>
                      ) : header.key === 'featured' ? (
                        item[header.key] == false ? <span>N</span> : <span>Y</span>
                      ) : header.key === 'publish' ? (
                        item[header.key] == false ? <span>N</span> : <span>Y</span>
                      ) : header.key === 'createdAt' ? (
                        formatDate(item[header.key])
                      ) : (
                        Array.isArray(item[header.key]) ? (
                          item[header.key]?.map((nestedItem, index) => (
                            <Box key={index} mb={2}>
                              {header?.headers?.map((nestedHeader) => (
                                <Box key={nestedHeader.key}>
                                  {getNestedPropertyValue(nestedItem, nestedHeader?.key)}
                                </Box>
                              ))}
                            </Box>
                          ))
                        ) : (
                          getNestedPropertyValue(item, header.key)
                        )
                      )
                    )}
                  </Td>
                ))}
                {buttons?.length !== 0 && (
                  <Td style={tdStyles} textAlign={'center'} justifyContent={'center'}>
                    {editRowId === item._id ? (
                      <Button size="sm" colorScheme="blue" onClick={() => handleSaveClick(item._id)}>Save</Button>
                    ) : (
                      <Button size="sm" colorScheme="blue" onClick={() => handleEditClick(item._id, item)}>Edit</Button>
                    )}
                  </Td>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={headers?.length + 1} style={tdStyles}>
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {(pagination == true) && (
        <>
          <Flex justifyContent="flex-start" fontSize={12}>Page {page || 1} to {pageSize || 1} of {totalItems || 0} entries</Flex>
          <Flex justifyContent="flex-end">
            <Pagination currentPage={page} totalPages={pageSize} onPageChange={onPageChange} />
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DataTableEdit;
