import React, { useState } from 'react';
import StepFormLayout from '../../common/components/StepFormLayout';
import DashboardLayout from '../../common/components/DashboardLayout';
import DynamicForm from '../../common/components/DynamicForms';

const LoanAppraisal = () => {
  const [formData, setFormData] = useState({});
  const handleInputChange = (event) => {
    if (event.groupIndex !== undefined) {
      // Handle fields within a group
      const { name, value, groupIndex } = event;

      setFormData((prevFormDatas) => {
        const updatedFormDatas = { ...prevFormDatas };
        const fieldData = updatedFormDatas[name] || [];
    
        // Update the value within the group at the specified index
        fieldData[groupIndex] = value;
    
        // Update the state with the new formData
        updatedFormDatas[name] = fieldData;
    
        return updatedFormDatas;
      });
      
    } else {
      // Handle fields outside of groups
      const { name, value } = event.target;
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    console.log(formData)
  };
  
  

  
  
  const fields1 = [
    {
        group: 'Internal_Borrowing_History',
        section: 'Internal Borrowing History',
        fields: [
            {
                label: 'Date Disbursed',
                name: 'intDate',
                type: 'date',

            },
            {
                label: 'Expiry Date',
                name: 'intExp',
                type: 'date',

            },
            {
                label: 'Amount',
                name: 'intAmt',
                type: 'text',

            },
            {
                label: 'Outstanding',
                name: 'intOut',
                type: 'text',

            },
            {
                label: 'Classification',
                name: 'intCla',
                type: 'select',
                options: [
                    '- Select Classification -',
                    'Performing',
                    'Watchlist',
                    'Sub Standard',
                    'Doubtful',
                    'Lost',
                    'Written Off',
                ],

            },
            {
                label: 'Lien',
                name: 'intLien',
                type: 'text',

            },
            {
                label: 'Status',
                name: 'intStat',
                type: 'select',
                options: [
                    '- Select Status -',
                    'Open',
                    'Closed',
                ],

            },
        ],
    },
    {
        group: 'External_Borrowing_History',
        section: 'External Borrowing History',
        fields: [
            {
                label: 'Date',
                name: 'extDate',
                type: 'date',
                min: '2010-01-01',
                max: '2205-12-31',
                value: '0000-00-00',

            },
            {
                label: 'Institution',
                name: 'extInst',
                type: 'text',
                value: 'N/A',

            },
            {
                label: 'Amount',
                name: 'extAmt',
                type: 'text',

            },
            {
                label: 'Outstanding',
                name: 'extOut',
                type: 'text',

            },
            {
                label: 'Tenor (Months)',
                name: 'extTen',
                type: 'text',
                maxlength: 2,
                size: 2,
                value: '0',

            },
            {
                label: 'Purpose',
                name: 'extPur',
                type: 'text',
                value: 'N/A',

            },
            {
                label: 'Status',
                name: 'extStat',
                type: 'select',
                options: [
                    '- Select Status -',
                    'Open',
                    'Closed',
                ],

            },
        ],
    },
    { 
      group: 'Institution',
      // section: 'Tur',
      fields:[
    {
        label: 'Turnover Dates (Month/Year)',
        // width: '100%',
        readonly: true,
        type: 'hidden',
    },
    {
        label: 'Institution 1',
        name: 'turnInst1',
        type: 'select',
        options: [
            '- Select Institution -',
            'Access Bank',
            'Access Bank (Diamond Bank)',
            'Citibank',
            'Ecobank',
            'Fidelity Bank',
            'First Bank',
            'FCMB',
            'Globus Bank',
            'GTB',
            'Heritage Bank',
            'Jaiz Bank',
            'Keystone Bank',
            'Kuda',
            'MoniePoint',
            'OPAY Digital',
            'Palm Pay',
            'Polaris Bank',
            'Providus Bank',
            'Stanbic IBTC Bank',
            'Standard Chartered Bank',
            'Sterling Bank',
            'Suntrust Bank',
            'Union Bank',
            'UBA',
            'Unity Bank',
            'Wema Bank',
            'Zenith Bank',
        ],
        // width: '100%',
    },
    {
        label: 'Institution 2',
        name: 'turnInst2',
        type: 'select',
        options: [
            '- Select Institution -',
            'Access Bank',
            'Access Bank (Diamond Bank)',
            'Citibank',
            'Ecobank',
            'Fidelity Bank',
            'First Bank',
            'FCMB',
            'Globus Bank',
            'GTB',
            'Heritage Bank',
            'Jaiz Bank',
            'Keystone Bank',
            'Kuda',
            'MoniePoint',
            'OPAY Digital',
            'Palm Pay',
            'Polaris Bank',
            'Providus Bank',
            'Stanbic IBTC Bank',
            'Standard Chartered Bank',
            'Sterling Bank',
            'Suntrust Bank',
            'Union Bank',
            'UBA',
            'Unity Bank',
            'Wema Bank',
            'Zenith Bank',
        ],
        // width: '100%',
    },
    {
        label: 'Institution 3',
        name: 'turnInst3',
        type: 'select',
        options: [
            '- Select Institution -',
            'Access Bank',
            'Access Bank (Diamond Bank)',
            'Citibank',
            'Ecobank',
            'Fidelity Bank',
            'First Bank',
            'FCMB',
            'Globus Bank',
            'GTB',
            'Heritage Bank',
            'Jaiz Bank',
            'Keystone Bank',
            'Kuda',
            'MoniePoint',
            'OPAY Digital',
            'Palm Pay',
            'Polaris Bank',
            'Providus Bank',
            'Stanbic IBTC Bank',
            'Standard Chartered Bank',
            'Sterling Bank',
            'Suntrust Bank',
            'Union Bank',
            'UBA',
            'Unity Bank',
            'Wema Bank',
            'Zenith Bank',
        ],
        // width: '100%',
    }]},
    {
        group: 'Bank Turnover',
        increment: true,
        fields: [
            {
                label: 'Month',
                name: 'turnDate',
                type: 'month',
                section: 'Bank Turnover',
            },
            {
                label: 'Turnover 1',
                name: 'turnAmt1',
                type: 'text',
                placeholder: 'Turnover',
                value: '0',
               width: '11%',
            },
            {
                label: 'CNT 1',
                name: 'turnCnt1',
                type: 'text',
                placeholder: 'CNT',
                value: '0',
               width: '11%',
            },
            {
                label: 'Turnover 2',
                name: 'turnAmt2',
                type: 'text',
                placeholder: 'Turnover',
                value: '0',
               width: '11%'
            },
            {
                label: 'CNT 2',
                name: 'turnCnt2',
                type: 'text',
                placeholder: 'CNT',
                value: '0',
               width: '11%'
            },
            {
                label: 'Turnover 3',
                name: 'turnAmt3',
                type: 'text',
                placeholder: 'Turnover',
                value: '0',
               width: '11%'
            },
            {
                label: 'CNT 3',
                name: 'turnCnt3',
                type: 'text',
                placeholder: 'CNT',
                value: '0',
               width: '11%'
            },
        ],
    },
      {
        group: 'Credit Score',
        section: 'Credit Score',
        fields: [
          {
            label: 'Sector Grade',
            name: 'creditSectorGrade',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Loyalty',
            name: 'creditLoyalty',
            type: 'select',
            width: '45%'
            
          },
          {
            label: 'Installment',
            name: 'creditInstallment',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Average Turnover',
            name: 'creditTurnover',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Average Count',
            name: 'creditCount',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Credit History - DPD on loans',
            name: 'creditHistory',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Active loans with other institution',
            name: 'creditActiveLoans',
            type: 'select',
            width: '45%'
          },
          {
            label: 'DTIR',
            name: 'creditDTIR',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Collateral Type',
            name: 'creditCollateralType',
            type: 'select',
            width: '45%'
          },
          {
            label: 'Collateral Value',
            name: 'creditCollateralValue',
            type: 'select',
            width: '45%'
          },
        ],
      },
      {
        group: 'Appraisal and Comment',
        section: 'Appraisal and Comment',
        fields: [
          {
            label: 'Consumable/Stock',
            name: 'consumableStock',
            type: 'text'
          },
          {
            label: 'Cash at Bank',
            name: 'cashAtBank',
            type: 'text'
          },
          {
            label: 'Prepayment',
            name: 'prepayment',
            type: 'text',
          },{
            label: 'Fixed Asset Valuation',
            name: 'fixedAssetValuation',
            type: 'text',
          },{
            label: 'Default',
            name: 'default',
            type: 'text',
          },{
            label: 'DTI',
            name: 'dti',
            type: 'text',
          },{
            label: 'Cost of Production/Execution/Service',
            name: 'costProduction',
            type: 'text',
          },{
            label: 'Monthly Sales/Revenue/Turnover',
            name: 'monthlySales',
            type: 'text',
          },{
            label: 'Margin',
            name: 'margin',
            type: 'text',
          },
          {
            label: 'Monthly Gross Income',
            name: 'grossIncome',
            type: 'text',
            readonly: true
          },
          {
            label: 'Monthly Expenses',
            name: 'monthlyExpenses',
            type: 'text',
          },
          {
            label: 'Net Income',
            name: 'netIncome',
            type: 'text',
          },
          {
            label: 'Amount Recommended',
            name: 'amountRecommended',
            type: 'text',
          },
          {
            label: 'Tenor (Months/Week)',
            name: 'tenor',
            type: 'text',
          },
          {
            label: 'Interest',
            name: 'interest',
            type: 'text',
          },
          {
            label: 'Lien',
            name: 'lien',
            type: 'text',
          },
          {
            label: 'Addosser Rentals',
            name: 'addosserRentals',
            type: 'text',
            readonly: true
          },
          {
            label: 'Asset to Loan Ratio (min 2:1)',
            name: 'prepayment',
            type: 'text',
          },
          {
            label: 'Gearing Ratio (max of 45%)',
            name: 'prepayment',
            type: 'text',
          },
          {
            label: 'Tangible Collateral Ratio',
            name: 'collateralRatio',
            type: 'text',
          },
          {
            label: 'NCR (Status)',
            name: 'prepayment',
            type: 'select',
          },
          {
            label: 'Score Card',
            name: 'scoreCard',
            type: 'text',
          },
        ],
      },
      {
        group: 'Collateral',
        section: 'Collateral',
        fields: [
      {
        label: 'Type',
        name: 'securitiesTitle',
        type: 'select',
        options: [
          '- Select Type -',
          'Cash (Lien)',
          'Vehicle',
          'Jewelry',
          'Stock Hypothecation',
          'Real Estate (Building)',
          'Real Estate (Lock-up shop)',
          'Fixed Asset',
        ],
        width: '23%'
      },
      {
        label: 'Owner',
        name: 'securitiesOwner',
        type: 'select',
        options: ['- Select Owner -', 'Borrower', 'Guarantor', 'Collateral Provider'],
        width: '23%'
      },
      {
        label: 'Market Value',
        name: 'securitiesValue',
        type: 'text',
        width: '23%'
      },
      {
        label: 'Forced Value',
        name: 'securitiesForcedValue',
        type: 'text',
        width: '23%'
      },
      {
        label: 'Description',
        name: 'securitiesDescription',
        type: 'textarea',
        
      
      }],
      increment: true
    },
    {
      group:'Comment',
      section: 'Comment',
      fields:[
        {
          label:'Comment',
          type:'textarea',
          name:'comment'
        }]},
        {
          group: 'Gurantor',
          section: 'Guarantor',
          increment: 'yes',
          fields:[
        {
          label: 'Name',
          type: 'text',
          name: 'guarantorName',
        },
        {
          label: 'Address',
          type: 'text',
          name: 'guarantorAddress',
        },
        {
          label: 'Phone Number',
          type: 'text',
          name: 'guarantorPhone',
        },

        {
          label: 'Verified',
          type: 'select',
          name: 'guarantorVerified',
        }
      ]},
      {
        group:'upload',
        section: 'File Uploads',
        increment: true,
        fields:[
          {
            label:'Select File',
            type:'file',
            name:'file'
          },
          {
            label:'File Name',
            type:'text',
            name:'fileTitle'
          }
        ]
      }
    ]
  
 

  // Define your form field configurations for each step
  const steps = [
    { fields: fields1 }
  ];

  return (
    <DashboardLayout>
      <StepFormLayout
        formData={formData}
        stepUrls={['loan-appraisal']}
        returns={[null, null, null]}
        setFormData={setFormData}
      >
        {steps.map((stepFields, index) => (
          <DynamicForm
            key={`step-${index}`}
            fields={stepFields.fields}
            formDatas={formData}
            handleInputChange={handleInputChange}
          />
        ))}
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default LoanAppraisal;
