import React, { useState } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Input, Button } from '@chakra-ui/react';

const DynamicTabForm = ({ tabs }) => {
  const [bronze, setBronze] = useState(40);
  const [silver, setSilver] = useState(30);
  const [gold, setGold] = useState('');
  const [diamond, setDiamond] = useState('');
  const [platinum, setPlatinum] = useState('');

  const handleSave = () => {
    // Handle saving data to your backend or perform other actions
    console.log('Saved:', { bronze, silver, gold, diamond, platinum });
  };

  return (
    <Box p={4} bg="white">
      <Tabs isLazy>
        <TabList> 
          {tabs?.header?.map((tab, index) => (
            <Tab  fontSize={12} key={index}>{tab}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabs?.content?.map((content, index) => (

            <TabPanel key={index}>
                
                {content}
                {/* <Button mt={4} colorScheme="teal" onClick={handleSave}>
                    Save  
                </Button> */}
            </TabPanel>
          ))}

        </TabPanels>

        
      </Tabs>
    </Box>
  );
};

export default DynamicTabForm;
