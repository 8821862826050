import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  VStack,
  Text,
  Heading,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { products } from '../api';
import useApi from '../hooks/useApi';

const ScrollableProductList = ({ onSelectionChange }) => {
  const { data: product, loading, error, execute } = useApi(products);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    execute();
  }, [execute]);

  // Notify the parent component of the selection change
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedProducts);
    }
  }, [selectedProducts, onSelectionChange]);

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked && product) {
      setSelectedProducts(product.map((item) => item.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const isAllSelected = product && selectedProducts.length === product.length;

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">Failed to load products.</Text>;
  }

  return (
    <Box p={4} w="300px" borderWidth="1px" borderRadius="md" boxShadow="md">
      <Heading fontSize={11} mb={1}>
        Select Products
      </Heading>
      <Divider mb={1} />
      <Box
        maxH="100px"
        overflowY="auto"
        borderWidth="1px"
        borderRadius="md"
        p={2}
        sx={{
          // Custom scrollbar styles
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#2E456B', // Primary color
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#FEA002', // Secondary color
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F0F0F0',
            borderRadius: '8px',
          },
        }}
      >
        <VStack align="start" spacing={3}>
          {/* "All" Checkbox */}
          <Checkbox
            isChecked={isAllSelected}
            onChange={(e) => handleSelectAll(e.target.checked)}
          >
            <Text fontSize={11}>All</Text>
          </Checkbox>

          {/* List of Product Checkboxes */}
          {product && product.length > 0 ? (
            product.map((item) => (
              <Checkbox
                key={item.id}
                isChecked={selectedProducts.includes(item.id)}
                onChange={() => handleCheckboxChange(item.id)}
              >
                <Text fontSize={11}>{item.name}</Text>
              </Checkbox>
            ))
          ) : (
            <Text>No products available.</Text>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default ScrollableProductList;
