import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import useFetch from '../../common/hooks/useFetch';

const CreateBudget = ({ isOpen, onClose }) => {
    const { data: businessUnits, loading: loadUnit, error: unitError, fetchData: fetchUnit } = useFetch("Auth API");
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    monthOne: "",
    monthTwo: "",
    monthThree: "",
    monthFour: "",
    monthFive: "",
    monthSix: "",
    monthSeven: "",
    monthEight: "",
    monthNine: "",
    monthTen: "",
    monthEleven: "",
    monthTwelve: "",
    type: "",
  });


  useEffect(() => {
    fetchUnit('business_unit');
  }, [businessUnits]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log(formData);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Budget</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>ID</FormLabel>
            <Input name="id" value={formData.id} onChange={handleChange} />

            <FormLabel>Name</FormLabel>
            <Select name="name" value={formData.type} onChange={handleChange}>
            {businessUnits && businessUnits.map((unit) => (
                <option key={unit._id} value={unit._id}>
                  {unit.BU_NM}
                </option>
              ))}
            </Select>
         

            <FormLabel>Month One</FormLabel>
            <Input name="monthOne" value={formData.monthOne} onChange={handleChange} />

            <FormLabel>Month Two</FormLabel>
            <Input name="monthTwo" value={formData.monthTwo} onChange={handleChange} />

            <FormLabel>Month Three</FormLabel>
            <Input name="monthThree" value={formData.monthThree} onChange={handleChange} />

            <FormLabel>Month Four</FormLabel>
            <Input name="monthFour" value={formData.monthFour} onChange={handleChange} />

            <FormLabel>Month Five</FormLabel>
            <Input name="monthFive" value={formData.monthFive} onChange={handleChange} />

            <FormLabel>Month Six</FormLabel>
            <Input name="monthSix" value={formData.monthSix} onChange={handleChange} />

            <FormLabel>Month Seven</FormLabel>
            <Input name="monthSeven" value={formData.monthSeven} onChange={handleChange} />

            <FormLabel>Month Eight</FormLabel>
            <Input name="monthEight" value={formData.monthEight} onChange={handleChange} />

            <FormLabel>Month Nine</FormLabel>
            <Input name="monthNine" value={formData.monthNine} onChange={handleChange} />

            <FormLabel>Month Ten</FormLabel>
            <Input name="monthTen" value={formData.monthTen} onChange={handleChange} />

            <FormLabel>Month Eleven</FormLabel>
            <Input name="monthEleven" value={formData.monthEleven} onChange={handleChange} />

            <FormLabel>Month Twelve</FormLabel>
            <Input name="monthTwelve" value={formData.monthTwelve} onChange={handleChange} />

            <FormLabel>Type</FormLabel>
            <Select name="type" value={formData.type} onChange={handleChange}>
              <option value="Type1">Type1</option>
              <option value="Type2">Type2</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateBudget;
