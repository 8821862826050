import React, { useState, useEffect, useRef } from 'react';
import { Select, Input, Box, Text, useDisclosure } from '@chakra-ui/react';

import { fetchData } from '../api';
import useApi from '../hooks/useApi';

const CustomSelect = ({ id, url, api, handleSelectChange }) => {
  const { data, loading, error, execute } = useApi(fetchData);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [display, setDisplay] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dropdownRef = useRef();


  
  useEffect(() => {
    execute(url);
  }, [url,execute]);


  useEffect(() => {
    if (Array.isArray(data)) {
      setFilteredOptions(
        data.filter((item) => {
          const name = item?.name?.toLowerCase() || '';
          const buNm = item?.BU_NM?.toLowerCase() || '';
          const marketName = item?.MARKET_NAME?.toLowerCase() || '';
          const fullName = `${item?.firstName || ''} ${item?.lastName || ''}`.toLowerCase();
          const search = searchTerm.toLowerCase();

          return (
            name.includes(search) ||
            buNm.includes(search) ||
            marketName.includes(search) ||
            fullName.includes(search)
          );
        })
      );
    }
  }, [data, searchTerm]);

  const handleInputChange = (value) => {
    setSearchTerm(value);
    onOpen();
    setDisplay("");
  };

  const handleSelectOption = (item) => {
    const selected = item?._id || item?.MARKET_NAME || item?.orbitID || item?.BU_NM || '';
    const sel = item?.name || item?.BU_NM || item?.MARKET_NAME || `${item?.firstName} ${item?.lastName}` || 'Unknown';
    // console.log(item)
    setSelectedValue(selected);
    handleSelectChange(selected, id);
    setDisplay(sel);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Box>
      <Box position="relative" ref={dropdownRef}>
        {!loading && data?.length < 20 ?
        <Select
        placeholder={loading ? 'Loading...' : `Select ${id || 'Item'}`}
        variant="filled"
        bg="white"
        border="1px solid"
        borderColor="gray.300"
        fontSize={12}
        onChange={(e) => {
            const selectedItem = data.find(item => item.id === e.target.value);
            handleInputChange(e.target.value);
            handleSelectOption(selectedItem);
        }}
    >
        {data?.map((item) => (
            <option key={item.id} value={item.id}>
                {item.MARKET_NAME || item.BU_NM || item.name || `${item.firstName} ${item.lastName}` || 'No data found'}
            </option>
        ))}
    </Select>
    :
        <>
        <Input
          placeholder={loading ? 'Loading...': `Search ${id || 'Item'}`}
          variant="filled"
          bg="white"
          border="1px solid grey.300"
          fontSize={12}
          value={searchTerm}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {isOpen && (
          <Box
            position="absolute"
            width="100%"
            mt={2}
            boxShadow="md"
            bg="white"
            fontWeight={400}
            borderRadius="md"
            zIndex={10}
            maxHeight="200px"
            overflowY="auto"
          >
            {filteredOptions.map((item) => (
              <Text
                key={item?.orbitID || item?._id || item?.MARKET_NAME || Math.random()}
                px={4}
                py={2}
                fontSize={12}
                fontWeight={400}
                cursor="pointer"
                _hover={{ background: 'gray.100' }}
                onClick={() => handleSelectOption(item)
                }
              >
                {item?.MARKET_NAME || item?.BU_NM || item?.name || `${item?.firstName} ${item?.lastName}` || 'No data found'}
              </Text>
            ))}
          </Box>
        )}</>}
      </Box>
        
      <Box m="2">Selected: {display}</Box>
    </Box>
  );
};

export default CustomSelect;
