import { useState, useEffect } from 'react';

const useMonthsUsed = (startDate) => {
  const [monthsUsed, setMonthsUsed] = useState(0);

  useEffect(() => {
    const calculateMonthsUsed = () => {
      const startDateObj = new Date(startDate);
      const currentDate = new Date();

      const diffInMonths =
        (currentDate.getFullYear() - startDateObj.getFullYear()) * 12 +
        (currentDate.getMonth() - startDateObj.getMonth());

      setMonthsUsed(diffInMonths);
    };

    calculateMonthsUsed();
  }, [startDate]);

  return monthsUsed;
};

export default useMonthsUsed