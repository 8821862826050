// src.js
import { AUTH_BASE_URL,API_BASE_URL } from './config/config';

export const details = (data, token) =>
  fetch(`${API_BASE_URL}/fetch/details`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

export const transaction = (data, token) =>
  fetch(`${API_BASE_URL}/fetch/transactions`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

export const withdrawal = (data, token) =>
  fetch(`${API_BASE_URL}/fetch/fetchWithdrawal`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  export const cabal = (data, token) =>
  fetch(`${API_BASE_URL}/fetch/fetchCabal`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });


export const account = (data, token) =>
  fetch(`${API_BASE_URL}/fetch/accountOpened`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
export const product = (token) =>
  fetch(`${API_BASE_URL}/productCategory`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  
 export const caseload = (token) =>
  fetch(`${API_BASE_URL}/caseload`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  
 export const visitation = (token) =>
  fetch(`${API_BASE_URL}/visitation`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  
 export const dmotarget = (token) =>
  fetch(`${API_BASE_URL}/dmotarget`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  
 export const activesaver = (token) =>
  fetch(`${API_BASE_URL}/activesavers`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  
export const mobilization = (token) =>
  fetch(`${API_BASE_URL}/dailymobilization`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });
  export const products = (token) =>
  fetch(`${API_BASE_URL}/products`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  });

  export const fetchData = (apiUrl, searchText, page, limit, token) =>{
  return fetch(`${apiUrl.includes("auth") ? AUTH_BASE_URL :API_BASE_URL}/${apiUrl.includes("auth") ? apiUrl?.split("/").slice(1).join("/"): apiUrl}?q=${searchText}&page=${page}&limit=${limit}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    },
  });
}

export const submitForm = (apiUrl, formData, token) => 
  fetch(`${apiUrl.includes("auth") ? AUTH_BASE_URL :API_BASE_URL}/${apiUrl.includes("auth") ? apiUrl?.split("/").slice(1).join("/"): apiUrl}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
  });  
  
  