// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, Box , Grid, Text, useMediaQuery, Spinner, Button} from '@chakra-ui/react';
import DashboardLayout from '../../common/components/DashboardLayout';
import useMonthsUsed from '../../common/hooks/usedMonths';
import SelectByPermission from '../../common/components/SelectByPermission';
import Budget from '../components/BudgetCard';
import DateFilter from '../../common/components/DateFilter';
import { useSelector, useDispatch } from 'react-redux';
import useApi from "../../common/hooks/useApi";
import useFormSubmission from '../../common/hooks/useFormSubmission';
import ReportHeader from '../components/ReportHeader';
import { cabal, details, account, withdrawal, product, caseload, visitation, dmotarget, activesaver, mobilization } from '../../common/api';
import ScrollableProductList from '../../common/components/ScrollableProductList';
const ReportPage = () => {
  const [group, setSelectedProductIds] = useState([]);
  let [ data , setData ] =useState();
  let [withdrawals, setWithdrawals] = useState();
  let [cabals, setCabals] = useState();
  let [accounts, setAccounts] = useState();
  let [ data2 , setData2 ] =useState();
  let [ chartData , setChartData ] = useState([]);

  const userPermission = useSelector((state) => state?.auth?.user?.role);
  const { user } = useSelector((state) => state.auth);
  let [ ids , setIds ] =useState(user?._id);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); // Set to 'today' initially
  const [ daysSelected, numOfDays] = useState(1);
  const [ permission, setPermission] = useState(null);
  let [customStartDate, setCustomStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [customEndDate, setCustomEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [detailsResult, setDetailsResult] = useState(null);
  const [cabalResult, setCabalResult] = useState(null);
  const [accountResult, setAccountResult] = useState(null);
  const [withdrawalResult, setWithdrawalResult] = useState(null);  
  const monthsUsed = useMonthsUsed(user?.startDate);  
  const handleProductsSelected = (selectedIds) => {
    console.log(selectedIds)
    setSelectedProductIds(selectedIds);
  };

  const { submitError, submitForm } = useFormSubmission("Savings API");

  const { loading: loadingData1, error: fetchError1, execute: fetchDetails } = useApi(details);
  const { loading: loadingData3, error: fetchError3, execute: fetchCabal } = useApi(cabal);
  const { loading: loadingData2, error: fetchError2, execute: fetchAccount } = useApi(account);
  const {  loading: loadingData4, error: fetchError4, execute: fetchWithdrawal } = useApi(withdrawal);
  
  const { data: productData, loading: productLoading, error: productError, execute: fetchProduct } = useApi(product);
  const { data: caseloadData, loading: caseloadLoading, error: caseloadError, execute: fetchCaseload } = useApi(caseload);
  const { data: visitationData, loading: visitationLoading, error: visitationError, execute: fetchVisitation } = useApi(visitation);
  const { data: dmotargetData, loading: dmotargetLoading, error: dmotargetError, execute: fetchDMOTarget } = useApi(dmotarget);
  const { data: activesaversData, loading: activesaversLoading, error: activesaversError, execute: fetchActiveSavers } = useApi(activesaver);
  const { data: dailymobilizationData, loading: dailymobilizationLoading, error: dailymobilizationError, execute: fetchDailyMobilization } = useApi(mobilization);


  
  
  useEffect(() => {
    const getTimeline = async () => {
          await fetchProduct();
          await fetchCaseload();
          await fetchVisitation();
          await fetchDMOTarget();
          await fetchActiveSavers();        
          await fetchDailyMobilization();
    };
    getTimeline();
    
  }, [fetchProduct, fetchCaseload, fetchVisitation, fetchDMOTarget, fetchActiveSavers, fetchDailyMobilization]);
  

  const handleFilterChange = async (value, startDate, endDate) => {
    setFilterValue(value);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);   
    
  };

  const handleSelectChange = async(event, selectId) => {
    setIds(event);
    setPermission(selectId)
    
  };
  
 

  const searchButton = async () => {
    setLoading(true);  
    setError(null);    
    try {
      const [details, cabal, account, withdrawal] = await Promise.all([
        fetchDetails({ group, role:userPermission?._id, 
          startDate:customStartDate, endDate: customEndDate,
           id:ids, permission }),
        fetchCabal({ group, role:userPermission?._id, 
          startDate:customStartDate, endDate: customEndDate, id:ids, permission }),
        fetchAccount({ group, role:userPermission?._id, 
          startDate:customStartDate, endDate: customEndDate, id:ids, permission }),
        fetchWithdrawal({ group,
           startDate:customStartDate, endDate: customEndDate, id:ids, permission }),
      ]);
      
      setDetailsResult(details?.data[0]);
      setCabalResult(cabal?.data[0]);
      setAccountResult(account?.data?.data[0]);
      setWithdrawalResult(withdrawal?.data.data[0]);
      
    } catch (err) {
      setError(err.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false); 
    }
    await dataFetch(userPermission?._id, customStartDate, customEndDate, ids, permission)
    
  };


  
  const dataFetch = async (role, startDate, endDate, id, permission) => {
    try {
      setLoading2(true);
      
      // Fetch details
      const { submitSuccess, responseBody, submitError } = await submitForm('fetchDetails', { 
        group, role, startDate, endDate, id, permission
      });
      
      // if (!submitSuccess) throw new Error(submitError);
  
      setData(responseBody.data);
      setData2(responseBody.data2);
     
      setChartData([
        { 
          name: "Amount Collected in thousands", 
          data: responseBody?.data?.totalAmountData?.sort(compareDates).map((item) => item.totalAmount)
        },
        { 
          name: "Collection", 
          data: responseBody?.data?.collectionData?.sort(compareDates).map((item) => item.collectionCount)
        },
        {
          name: "Visitation",
          data: responseBody?.data?.visitationData?.sort(compareDates)
        }
      ]);
  
      // Fetch cabal data
      const { submitSuccess: cabalSuccess, responseBody: cabalBody, submitError: cabalError } = await submitForm("fetchCabal", { 
        group, role, startDate, endDate, id, permission
      });
  
      // if (!cabalSuccess) throw new Error(cabalError);
  
      setCabals(cabalBody.data);
      
      // Fetch account data
      const { submitSuccess: accountSuccess, responseBody: accountBody, submitError: accountError } = await submitForm("accountOpened", { 
        group, role, startDate, endDate, id, permission
      });
      
      // if (!accountSuccess) throw new Error(accountError);
      
      setAccounts(accountBody?.data?.data);
      console.log(accountBody, "account opened")
      
      // Fetch withdrawal data
      const { submitSuccess: withdrawSuccess, responseBody: withdrawBody, submitError: withdrawalError } = await submitForm("fetchWithdrawal", {
        group, startDate, endDate,id, permission, ACCTS: cabalBody?.data?.cabalMix?.accounts?.map((item) => item.ACCT_NO) || [],
      });
  
      // if (!withdrawSuccess) throw new Error(withdrawalError);
  
      setWithdrawals(withdrawBody.data);
  
    } catch (error) {
      console.error("Data fetch error: ", error.message);
    } finally {
      setLoading2(false);
    }
  };
  
    const compareDates = (a, b) => {
    const formattedDateA = new Date(a.dateKey);
    const formattedDateB = new Date(b.dateKey);
    return formattedDateA - formattedDateB
  };
  
  

  const visitCat = (category) => {
    let target = visitationData?.filter((item) => item?.category.toLowerCase() === category?.toLowerCase())[0];
    
    if (target) {
      let result = target?.serviceLengths.find((item) => {
        let [start, end] = item.lengths.split('-').map(Number);
       
  
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          return item?.visitation;
        }
      });
      
      return result?.visitation;
    }
    
    return 0; 
  };
     
  const caseLoadStat = (now) =>{
      if (now){
    
      let result = caseloadData?.find((item) => {
        let [start, end] = item.serviceLength.split('-').map(Number);
        
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          return item?.minimumCaseLoad
        }
      })
    return result?.minimumCaseLoad;
      }
  }
  const activeMix = (category) => {
    let target = activesaversData?.filter((item) => item?.productCategory?.toLowerCase() === category?.toLowerCase())[0];
    
    if (target) {
      let result = target?.serviceLengths.find((item) => {
        let [start, end] = item.lengths.split('-').map(Number);
        console.log(start, end, monthsUsed)
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          
          return item?.numberOfCustomers
        }
  
        return false; 
      });
      return result?.numberOfCustomers; 
    }
  
    return 0; 
  };
  
    
  return (
    <DashboardLayout>

    <Box p={4}>
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} className='no-print'>
   
   <Text mb={4} fontSize="11" fontWeight={600}>
   <SelectByPermission userPermission={userPermission} 
   user={user} handleSelectChangeCustom={handleSelectChange}/> 
   </Text>

 <DateFilter
       onFilterChange={handleFilterChange}
       useCustomDateFormat={true} 
       numOfDays={numOfDays}
     />
 </Grid>
 <Box justifyContent={'right'} display={'flex'} mb={3} className='no-print'>
    <Button onClick={searchButton} bg={"darkblue"} color={'white'} fontSize={12}> Submit</Button>
  </Box>
  <Box>
  <ScrollableProductList onSelectionChange={handleProductsSelected}  />
    {/* <ProductSelector onSelectionChange={handleProductsSelected} /> */}
  </Box>
    { loading ? <Spinner size="lg" /> :
    <Box bg="white" borderRadius={10} p={3}>
      <ReportHeader></ReportHeader>
    
      <Text fontSize={'18px'} fontWeight={600} justifyContent="center" display={'flex'} alignSelf={'center'}>SAVINGS REPORT FROM {customStartDate} TO {customEndDate} </Text>
      {/* Summary Section */}
      <section>
        <Table>
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Total Collections</Td>
              <Td>{detailsResult?.COLLECTION?.toLocaleString()}</Td>
            </Tr>
            <Tr>
              <Td>Total Visitations</Td>
              <Td>{detailsResult?.VISITATION?.toLocaleString()}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Collected</Td>
              <Td><span>&#8358;</span>{detailsResult?.TXN_AMT?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Accounts in Cabal</Td>
              <Td>{cabalResult?.NO_OF_ACCT}</Td>
            </Tr>
            <Tr>
              <Td>Total Active Accounts in Cabal</Td>
              <Td>{cabalResult?.ACTIVE}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn </Td>
              <Td><span>&#8358;</span>{withdrawalResult?.TXN_AMT?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn (AFTA)</Td>
              <Td><span>&#8358;</span>{withdrawalResult?.AFTA_AMT?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Account Opened</Td>
              <Td>{accountResult?.OPENED_ACCT}</Td>
            </Tr>
            <Tr>
              <Td>Current Balance of Active Accounts</Td>
              <Td> <span>&#8358;</span>{cabalResult?.ACTIVE_CURRENT_BAL?.toLocaleString()|| 0 } </Td>
            </Tr>
          </Tbody>
        </Table>
      </section>
      {permission == "users" || userPermission?.permissions?.find(permission => permission?.name.includes("Individual")) ?
      <>
      Category Mix Section
      <section>
        {/* Category Mix for Collections */}
        <h3>Category Mix for Collections:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Collection</Th>
              <Th>%</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={12}>
            {data?.categoryCounts && Object?.keys(data?.categoryCounts).map((category, index) => (
                <Tr 
              fontSize={10}
                key={category}>
                <Td fontWeight="600">{category === "null" ? "Non AFTA" : category}</Td>
                <Td>{loading ? <Spinner size="sm" /> : data?.categoryCounts[category]?.count || 0}</Td>
                <Td>{loading ? <Spinner size="sm" /> : data?.categoryCounts[category]?.percentage || 0} </Td>
              </Tr>   
            
            ))}
            </Tbody>
        </Table>

        {/* Category Mix for Visitation */}
        <h3>Category Mix for Visitation:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Visitation</Th>
              <Th>%</Th>
              <Th>Target</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={12}>
            {data?.categoryVisitation && Object?.keys(data?.categoryVisitation).map((category, index) => (
                <Tr 
              fontSize={10}
                key={category}>
                <Td fontWeight="600">{category === "null" ? "Non AFTA" : category}</Td>
                <Td
                color={visitCat(category) * daysSelected > data?.categoryVisitation[category]?.count
                ? 'red' : ''}
                >{loading ? <Spinner size="sm" /> : data?.categoryVisitation[category]?.count || 0}</Td>
                <Td>{loading ? <Spinner size="sm" /> : Math.round(((data?.categoryVisitation[category]?.count)/visitCat(category) * daysSelected) * 100)  || 0} </Td>
                <Td>{loading ? <Spinner size="sm" /> : visitCat(category) * daysSelected}</Td>
              </Tr>   
            
            ))}
            </Tbody>
        </Table>

        {/* Active Savers */}
        <h3>Active Savers:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Accounts</Th>
              <Th>%</Th>
              <Th>Target</Th>
            </Tr>
          </Thead>
          <Tbody>
          {cabals?.cabalMix?.categoryCounts && Object.keys(cabals?.cabalMix?.categoryCounts).map((category, index) => (
              <Tr 
              fontSize={10}
              key={category}>
              <Td fontWeight={600}>{category === "null" ? "Non AFTA" : category}</Td>
              <Td color={
                  Number(activeMix(category)) > Number(cabals?.cabalMix?.categoryCounts[category]?.count)
                  ? 'red' : ''

              }>{loading ? <Spinner size="sm" /> : cabals?.cabalMix?.categoryCounts[category]?.count || 0}</Td>
              <Td>{loading ? <Spinner size="sm" /> : Math.round((Number(cabals?.cabalMix?.categoryCounts[category]?.count)/(Number(activeMix(category)) * daysSelected)) * 100) || 0} </Td>
              <Td>{loading ? <Spinner size="sm" /> : (activeMix(category) * daysSelected )|| 0}</Td>
              
            </Tr>   
            ))}
          </Tbody>
        </Table>
        
      </section> </>:
        <Box  mb={5}  bg={"white"} borderRadius={10} mt={10}>
    <Budget permission={permission} ids={ids} customStartDate={customStartDate} customEndDate={customEndDate} />
    </Box>}
      </Box>}
      
      </Box>
      
    </DashboardLayout>
  );
};

export default ReportPage;
