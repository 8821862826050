import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import useFetch from '../../common/hooks/useFetch';
import moment from 'moment';

const Budget = ({ permission, ids, customStartDate, customEndDate }) => {
  const { data: budget = [], loading, error, fetchData } = useFetch("Savings API");
  const [filteredBudget, setFilteredBudget] = useState([]);
  const [monthsInWords, setMonthsInWords] = useState([]);

  // Get all months between customStartDate and customEndDate
  useEffect(() => {
    if (customStartDate && customEndDate) {
      const startMonth = moment(customStartDate).month();
      const endMonth = moment(customEndDate).month();
      const months = [];

      for (let month = startMonth; month <= endMonth; month++) {
        months.push(moment().month(month).format('MMMM'));
      }

      setMonthsInWords(months);
    }
  }, [customStartDate, customEndDate]);

  const replaceMonths = (arr) => {
    const months = {
      'January': 'monthOne',
      'February': 'monthTwo',
      'March': 'monthThree',
      'April': 'monthFour',
      'May': 'monthFive',
      'June': 'monthSix',
      'July': 'monthSeven',
      'August': 'monthEight',
      'September': 'monthNine',
      'October': 'monthTen',
      'November': 'monthEleven',
      'December': 'monthTwelve'
    };

    return arr.map(item => months[item] || item);
  };

  const filterByMonths = (dataArray, monthsArray) => {
    return dataArray.reduce((result, item) => {
      // Find the sum of all matching months
      let totalAmount = monthsArray.reduce((sum, monthField) => {
        if (item.hasOwnProperty(monthField)) {
          console.log(item[monthField], "m")
          const value = Number(String(item[monthField]).replace(/[^0-9.-]+/g, '')); // Convert the value to a number
          if (!isNaN(value)) {
            sum += value;  // Add the value only if it's a valid number
          }
        }
        return sum;
      }, 0);
  
      
      if (totalAmount > 0) {
        result.push({
          name: item.name,
          totalAmount,  // The sum of amounts for matching months
        });
      }
  
      return result;
    }, []);
  };
  
  

  // Refetch data whenever ids, permission, or customStartDate/customEndDate change
  useEffect(() => {
    fetchData('budgets');
  }, [ids, permission, customStartDate, customEndDate]);

  // Filter the budget whenever budget data, ids, or monthsInWords change
  useEffect(() => {
    if (Array.isArray(budget?.data)) {
      const newFilteredBudget = budget?.data?.filter(product => product?.orbitId == ids);
      const filteredByMonths = filterByMonths(newFilteredBudget, replaceMonths(monthsInWords));
      setFilteredBudget(filteredByMonths);
      console.log(filteredByMonths)
    }
    
    console.log(filteredBudget)
  }, [budget, ids, monthsInWords]);
  
  return (
    <Box padding="6" boxShadow="lg" bg="white">
      <Text mb="4" fontWeight={500}>Budget</Text>

      {loading && <Spinner size="xl" />}
      {!loading && error && (
        <Alert status="error">
          <AlertIcon />
          There was an error loading budget data.
        </Alert>
      )}

      {!loading && !error && filteredBudget.length > 0 && (
        <Box w={500}>
          {filteredBudget.map((product) => (
            <Box key={product?.id} mb="4" p="2" borderWidth="1px" borderRadius="lg">
              <Text fontSize="md">{product?.name}</Text>
              <Text fontSize="xl" color="green.500">
                N{product?.totalAmount?.toLocaleString()}
              </Text>
            </Box>
          ))}
        </Box>
      )}

      {!loading && !error && filteredBudget.length === 0 && (
        <Text>No budget data available for this filter.</Text>
      )}
    </Box>
  );
};

export default Budget;
