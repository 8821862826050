import React from 'react';
import { Grid, GridItem, Image, Text} from '@chakra-ui/react';


const ReportHeader = () =>{

    return(
        <Grid templateColumns='repeat(4, 1fr)' gap={1} p={2}>
        <GridItem>
      <Image
          src="/images/mfb.png"
          alt="Logo"
          maxW="80px"
          colSpan={1}
        />
        </GridItem>
        <GridItem colSpan={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Text fontSize={'28px'} fontWeight={600} >ADDOSSER MICROFINANCE BANK</Text>
      </GridItem>
      </Grid>

    )
}

export default ReportHeader;