import React, { useState, useEffect } from 'react';
import { Box, Button, Spinner, Text } from '@chakra-ui/react';
import DashboardLayout from '../../common/components/DashboardLayout';
import useFetch from '../../common/hooks/useFetch';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import CreateBudget from '../components/CreateBudget';
import DataTableEdit from '../../common/components/DataTableEdit';

const SetBudget = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Savings API");  
  const [message, setMessage] = useState();
  const { data: budget, loading, error, fetchData } = useFetch("Savings API");
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

  useEffect(() => {
    fetchData('budgets');
    
  }, [budget]);

  const handleSave = async(id, updatedData, name) => {
    
    const { submitSuccess, responseBody } = await submitForm(name+"/"+id, updatedData);
    if (submitSuccess){
        setMessage(responseBody.message)
    }
  };

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'January', key: 'monthOne' },
    { label: 'Febuary', key: 'monthTwo' },
    { label: 'March', key: 'monthThree' },
    { label: 'April', key: 'monthFour' },
    { label: 'May', key: 'monthFive' },
    { label: 'June', key: 'monthSix' },
    { label: 'July', key: 'monthSeven' },
    { label: 'August', key: 'monthEight' },
    { label: 'September', key: 'monthNine' },
    { label: 'October', key: 'monthTen' },
    { label: 'November', key: 'monthEleven' },
    { label: 'December', key: 'monthTwelve' },
  ];


  return (
    <DashboardLayout>
    
      
      <Box  w="100%" p={2}>
        <Button bg={"darkblue"} color="white" fontSize={12} onClick={openModal}>
            Open Form
        </Button>

            <CreateBudget  isOpen={isModalOpen} onClose={closeModal}  />
        <Box>
         <DataTableEdit
          data={budget?.data} 
          headers={headers}
          page={1}
          onPageChange={() => { }}
          pageSize={10}
          totalItems={100}
          loading={loading}
          onSave={handleSave}
          name={'budgets'}
        />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default SetBudget;
