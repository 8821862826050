// src/auth/auth-guard.js

import store from './store'; // Import your Redux store

const isAuthenticated = () => {
  const state = store.getState();
  return state.auth.isAuthenticated; // Adjust the path to your auth state in Redux
};

export const authGuard = (next) => {
  if (!isAuthenticated()) {
    next('/login');
  } else {
    next();
  }
};
