// ChangePassword.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match.');
      return;
    }

    // Here you would handle the password change logic, e.g., calling an API.
    // For demonstration, we assume the password change is successful.
    setSuccess('Password changed successfully.');
  };

  return (
    <DashboardLayout>
    <Box bg="white" p={5} borderRadius={10}>
      <Text fontSize={13} fontWeight={700} mb={6}>
        Change Password
      </Text>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {success && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Success!</AlertTitle>
          <AlertDescription>{success}</AlertDescription>
        </Alert>
      )}
      <Box as="form" onSubmit={handleSubmit} width={'50%'}>
        <Stack spacing={4}>
          <FormControl id="current-password" isRequired>
            <FormLabel fontSize={11}>Current Password</FormLabel>
            <Input
                fontSize={11}
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </FormControl>
          <FormControl id="new-password" isRequired>
            <FormLabel fontSize={11}>New Password</FormLabel>
            <Input
              fontSize={11}
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl id="confirm-password" isRequired>
            <FormLabel fontSize={11}>Confirm New Password</FormLabel>
            <Input
             fontSize={11}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          <Button fontSize={11} type="submit" background={'darkblue'} color="white" mt={4}>
            Change Password
          </Button>
        </Stack>
      </Box>
    </Box></DashboardLayout>
  );
};

export default ChangePassword;
